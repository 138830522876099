<template>
  <el-card class="box-card accountNum">
    <template #header>
      <div class="card-header">
        <span class="SubHead">{{ accountStateItem.title }}</span>
        <span>
          <el-button
            class="LinkText routerBtn"
            type="text"
            v-if="accountStateItem.active_rate >= 0"
            @click="importLicense"
            >新增广告账号
          </el-button>
          <el-button class="routerBtn" type="text" disabled v-else>新增广告账号</el-button>
        </span>
      </div>
    </template>

    <div class="text item">
      <ul>
        <el-row :gutter="20">
          <el-col :span="6" v-for="item in accountStateItem.accountState" :key="item.id">
            <li class="accountList">
              <p class="Text">
                {{ item.title }}
                <span v-show="accountStateItem.isshow">
                  <el-tooltip class="item" effect="light" content="" placement="top">
                    <template #content>
                      {{ item.tips }}
                    </template>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </span>
              </p>
              <p class="MainHead">{{ item.total }}</p>
            </li>
          </el-col>
        </el-row>
      </ul>
    </div>
  </el-card>

  <!-- GG开户流程对话框 -->
  <GGNewOpen v-if="'gg' === dialogType" @close="dialogType = ''"/>
</template>

<script>
import GGNewOpen from './GGNewOpen.vue'
export default {
  name: 'GGAccountState',
  components: { GGNewOpen },
  props: ['accountStateItem'],
  data() {
    return {
      userInfo: '',
      dialogShow: false,
      dialogType: ''
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
  },
  methods: {
    importLicense() {
      this.dialogType = 'gg'
    }
  }
}
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.routerBtn {
  padding: 0;
  min-height: auto;
}
.box-card {
  height: 180px;
}
.accountList p {
  margin: 10px 0;
}
.text {
  height: 80px;
}

i.el-icon-warning-outline {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}
</style>
