<template>
  <div>
    <el-row :gutter="20" class="firsrRow">
      <el-col :span="16">
        <TTAccountState :accountStateItem="accountStateItem"></TTAccountState>
      </el-col>

      <el-col :span="8">
        <balance-card type="tiktok"/>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="SubHead">所有账户( {{ totalNum }} )</span>
            </div>
          </template>
          <TTAllAccount />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TTAccountState from '../account/TTAccountState.vue'
import TTUsableBalance from '../account/TTUsableBalance.vue'
import TTAllAccount from '../account/TTAllAccount.vue'
import BalanceCard from "@/views/account/common/BalanceCard";

export default {
  name: 'TTAccountList',
  components: {
    BalanceCard,
    TTAccountState,
    TTUsableBalance,
    TTAllAccount
  },
  data() {
    return {
      totalNum: '',
      accountStateItem: {
        title: '',
        isshow: false,
        accountState: []
      }
    }
  },
  mounted() {
    this.getAllAccountList()
  },
  methods: {
    // 获取列表数据
    async getAllAccountList() {
      const { data: res } = await this.$http.post('cloudtt/ad_data')
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      this.accountStateItem = res.data.accountStateItem
      this.totalNum = res.data.total_num
    }
  }
}
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:deep(.el-tabs__header) {
  margin-bottom: 30px;
}
.firsrRow {
  margin-bottom: 20px;

  .accountNum .el-row {
    margin-bottom: 10px;
  }
}
i.el-icon-warning {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}
:deep(.el-tabs__item) {
  font-size: 16px !important;
}
:deep(.el-dialog__header) {
  padding: 15px 20px !important;
  border-bottom: 1px solid #ebebeb;
}
</style>
