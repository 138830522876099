<template>
  <el-form ref="accountFormRef" :inline="true" :model="accountForm">
    <el-row>
      <el-form-item label="广告账户" prop="adIdValue">
        <el-input placeholder="请输入账户ID或账户名称" size="small" clearable v-model.trim="accountForm.adIdValue"
          style="width: 220px"></el-input>
      </el-form-item>
      <el-form-item style="margin-left: 14px">
        <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
        <el-button @click="resetFormBtn" size="small">重置</el-button>
      </el-form-item>
      <span class="Text" style="margin-top: 12px">已选择{{ SelectNumber }}个</span>
      <!-- <el-form-item label="账户状态" prop="state">
        <el-select v-model="accountForm.state" placeholder="请选择状态" size="small">
          <el-option label="正常" value="1"></el-option>
          <el-option label="被封" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="BCID" prop="BCID">
        <el-input
          placeholder="请输入BCID"
          size="small"
          clearable
          v-model.trim="accountForm.BCID"
          style="width:220px"
        ></el-input>
      </el-form-item> -->
    </el-row>
    <el-row class="downLoadRow">
      <!-- <el-form-item label="营业执照" prop="company">
        <el-input
          placeholder="请输入营业执照"
          size="small"
          clearable
          v-model.trim="accountForm.company"
          style="width:220px"
        ></el-input>
      </el-form-item>
      <el-form-item label="选择日期" prop="timeRange">
        <el-date-picker
          v-model="accountForm.timeRange"
          type="daterange"
          size="small"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="X"
        ></el-date-picker>
      </el-form-item> -->

      <!-- <el-button type="text" id="downLoad" @click="downLoadExpend">导出账户消耗</el-button> -->
    </el-row>
  </el-form>

  <!-- <el-alert title="提示" description="" type="info" show-icon>
    <template #default>
      注意：余额同步时间预计2-3小时，账户充值同步时间大约为3-6小时，账户实时信息请以谷歌后台为准。
    </template>
  </el-alert> -->

  <el-table ref="allAccountRef" :data="allAccountList" :row-key="getAccountId" style="width: 100%" :header-cell-style="{
    background: 'var(--el-table-row-hover-background-color)',
  }" @selection-change="handleSelectionChange" @select="singleSelect" @select-all="selectAll" @row-click="rowClick">
    <el-table-column type="selection" :reserve-selection="true" :selectable="blockedRow" width="55" />
    <el-table-column property="" label="账户名称" min-width="150">
      <template #default="{ row }">
        {{ row.name }}
        <!-- <i class="el-icon-edit-outline Text" @click="editName(row)"></i> -->
      </template>
    </el-table-column>
    <el-table-column property="account_id" label="账户ID" min-width="150" />
    <!-- <el-table-column property="" label="营业执照" min-width="200">
      <template #default="{row}">
        {{ row.chinese_legal_entity_name }}
      </template>
    </el-table-column> -->
    <!-- <el-table-column label="账户状态"> -->
    <!-- <template #default="{ row }"> -->
    <!-- <el-tag type="success" v-if="row.status == 1">正常</el-tag> -->
    <!-- <el-tag type="danger" v-else>停用</el-tag> -->
    <!-- </template> -->
    <!-- </el-table-column> -->
    <!-- <el-table-column property="spent_x" label="消耗">
      <template #default="{row}"> ${{ $filters.thousands(row.spent_x) }}</template>
    </el-table-column> -->
    <el-table-column property="balance" label="账户余额" sortable min-width="120">
      <template #default="{ row }">
        <span> ${{ $filters.thousands(row.balance) }}</span>
      </template>
    </el-table-column>
    <!-- <el-table-column property="bc_ids" label="绑定的BC" min-width="300" /> -->
    <template #empty>
      <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
    </template>
  </el-table>

  <el-row>
    <el-col :span="12" style="padding-top: 15px">
      <el-button size="mini" @click="clearRemainder" :type="SelectNumber == 0 ? '' : 'primary'">余额清零</el-button>
      <el-button size="mini" @click="recharge" :type="SelectNumber == 0 ? '' : 'primary'"
        :disabled="fbServiceMode == 1 || fbServiceMode == 3">账户充值</el-button>
      <!-- <el-button size="mini" @click="BMUnbind" :type="SelectNumber == 0 ? '' : 'primary'">MCC解绑</el-button>
      <el-button size="mini" @click="BMBind" :type="SelectNumber == 0 ? '' : 'primary'">MCC绑定</el-button> -->
      <!-- <span class="Text" style="margin-left:15px;">已选择 {{ SelectNumber }} 个</span> -->
    </el-col>
    <el-col :span="12" style="text-align: right">
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
        :page-sizes="[2, 5, 10]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total" background>
      </el-pagination>
    </el-col>
  </el-row>

  <!-- 账户充值对话框 -->
  <el-dialog v-model="rechargeDialogVisible" title="账户充值" width="30%">
    <!-- 内容主体区域 -->
    <el-form ref="rechargeFormRef" :model="rechargeForm" :rules="rechargeFormRules" label-width="60px"
      @submit.native.prevent>
      <el-form-item label="金额" prop="money">
        <el-input size="small" placeholder="请输入充值金额$" clearable v-model="rechargeForm.money"
          @keyup.enter.native="rechargeSumBtn"></el-input>
        <span class="Text">可用余额：{{ balance }}美元</span>
      </el-form-item>
      <el-form-item label="预览">
        已选择 <strong class="color-primary">{{ SelectNumber }}</strong> 个账户，预计扣款
        <strong class="color-primary">{{ SelectNumber * rechargeForm.money }}</strong> 美元
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="rechargeFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="rechargeSumBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- BCID解绑对话框 -->
  <el-dialog v-model="BMUnbindDialogVisible" title="MCC解绑" width="30%">
    <!-- 内容主体区域 -->
    <el-form ref="BMUnbindFormRef" :model="BMUnbindForm" :rules="BMUnbindFormRules" label-width="60px"
      @submit.native.prevent>
      <el-form-item label-width="80px" label="MCCID" prop="unbindId">
        <el-input size="small" placeholder="请输入解绑的MCCID" clearable v-model.trim="BMUnbindForm.unbindId"
          @keyup.enter.native="BMUnbindBtn"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="BMUnbindFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="BMUnbindBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- MCCID绑定对话框 -->
  <el-dialog v-model="BMBindDialogVisible" title="MCC绑定" width="30%">
    <!-- 内容主体区域 -->
    <el-form ref="BMBindFormRef" :model="BMBindForm" :rules="BMBindFormRules" label-width="60px" @submit.native.prevent>
      <el-form-item label-width="80px" label="MCCID" prop="bindId">
        <el-input size="small" placeholder="请输入绑定的MCCID" clearable v-model.trim="BMBindForm.bindId"
          @keyup.enter.native="BMBindBtn"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="BMBindFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="BMBindBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 下载弹窗 -->
  <el-dialog v-model="downLoadDialogVisible" title="下载消耗" width="790px" custom-class="downLoadExpend">
    <!-- 内容主体区域 -->
    <el-form ref="downLoadFormRef" label-width="100px">
      <el-form-item label="选择时间" class="timeRange">
        <el-date-picker v-model="expendTimeRange" type="daterange" size="small" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="X"></el-date-picker>
      </el-form-item>
      <el-form-item label="选择账户">
        <el-radio v-model="accountType" label="1" size="small">所有账户</el-radio>
        <el-radio v-model="accountType" label="2" size="small">有消耗的账户</el-radio>
      </el-form-item>
      <el-form-item label="选择字段" class="downLoadForm">
        <el-transfer v-model="expendValue" style="text-align: left; display: inline-block" :left-default-checked="[
          'name',
          'advertiser_id',
          'status',
          'spent_x',
          'balance',
          'bc_ids',
          'chinese_legal_entity_name',
        ]" :right-default-checked="[]" :titles="['可选字段', '已选字段']" :button-texts="['移除', '添加']" :format="{
  noChecked: '${total}',
  hasChecked: '${checked}/${total}',
}" :data="expendData">
        </el-transfer>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="downLoadFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="downLoadFormBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 修改账户名称对话框 -->
  <el-dialog v-model="editNameDialogVisible" title="修改账户名" width="30%">
    <!-- 内容主体区域 -->
    <el-form ref="editNameFormRef" :model="editNameForm" :rules="editNameFormRules" label-width="100px">
      <el-form-item label="原账户名称">
        <el-input size="small" clearable :placeholder="oldName" disabled></el-input>
      </el-form-item>
      <el-form-item label="新账户名称" prop="accName">
        <el-input size="small" placeholder="请输入新的账户名称" clearable v-model.trim="editNameForm.accName"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="editNameDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="editNameBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElLoading } from 'element-plus'
import api from '@/request/api/account'

export default {
  name: 'GGAllAccount',
  data() {
    //浏览器高度
    var height = window.innerHeight
    //浏览器宽度
    var width = window.innerWidth
    //浏览器
    // 浏览器检查结果
    let browserInfo = getBrowserInfo()
    var browser = browserInfo.browserName + '的版本是：' + browserInfo.versionInfo
    function getBrowserInfo() {
      let sysObj = {}
      let browserInfo = navigator.userAgent.toLowerCase()
      let regular = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
      let vsMsg = browserInfo.match(regular)
      sysObj.browserName = vsMsg[1].replace(/version/, "'safari")
      sysObj.versionInfo = vsMsg[2]
      return sysObj
    }
    // 获取操作系统信息
    function getOsInfo() {
      var userAgent = navigator.userAgent.toLowerCase()
      var name = 'Unknown'
      var version = 'Unknown'
      if (userAgent.indexOf('win') > -1) {
        name = 'Windows'
        if (userAgent.indexOf('windows nt 5.0') > -1) {
          version = 'Windows 2000'
        } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
          version = 'Windows XP'
        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
          version = 'Windows Vista'
        } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
          version = 'Windows 7'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
          version = 'Windows 8'
        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
          version = 'Windows 8.1'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
          version = 'Windows 10'
        } else {
          version = 'Unknown'
        }
      } else if (userAgent.indexOf('iphone') > -1) {
        name = 'Iphone'
      } else if (userAgent.indexOf('mac') > -1) {
        name = 'Mac'
      } else if (
        userAgent.indexOf('x11') > -1 ||
        userAgent.indexOf('unix') > -1 ||
        userAgent.indexOf('sunname') > -1 ||
        userAgent.indexOf('bsd') > -1
      ) {
        name = 'Unix'
      } else if (userAgent.indexOf('linux') > -1) {
        if (userAgent.indexOf('android') > -1) {
          name = 'Android'
        } else {
          name = 'Linux'
        }
      } else {
        name = 'Unknown'
      }
      return { name, version }
    }
    var os = getOsInfo().name
    if (os == 'Windows') {
      os = getOsInfo().version
    }

    return {
      // 客户端信息
      width: width,
      height: height,
      browser: browser,
      os: os,
      userInfo: '',
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5,
      },
      // 充值对话框
      rechargeDialogVisible: false,
      // 添加用户的充值表单数据
      rechargeForm: {
        money: '',
      },
      // 添加充值表单的验证规则对象
      rechargeFormRules: {
        money: [
          { required: true, message: '请输入正确的金额', trigger: 'blur' },
          {
            pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
            message: '充值金额错误，请重新输入',
            trigger: 'blur',
          },
        ],
      },
      // 解绑对话框
      BMUnbindDialogVisible: false,
      // 添加用户的解绑表单数据
      BMUnbindForm: {
        unbindId: '',
      },
      // 添加解绑表单的验证规则对象
      BMUnbindFormRules: {
        unbindId: [
          { required: true, message: '请输入BCID', trigger: 'blur' },
          {
            pattern: /^[0-9]*$/,
            message: 'BCID格式不正确，应为纯数字',
            trigger: 'blur',
          },
          {
            min: 17,
            message: 'BCID格式不正确',
            trigger: 'blur',
          },
        ],
      },
      // 绑定对话框
      BMBindDialogVisible: false,
      // 添加用户的绑定表单数据
      BMBindForm: {
        bindId: '',
      },
      // 添加绑定表单的验证规则对象
      BMBindFormRules: {
        bindId: [
          { required: true, message: '请输入BCID', trigger: 'blur' },
          {
            pattern: /^[0-9]*$/,
            message: 'BCID格式不正确，应为纯数字',
            trigger: 'blur',
          },
          {
            min: 17,
            message: 'BCID格式不正确',
            trigger: 'blur',
          },
        ],
      },
      // 查询用户信息的表单数据
      accountForm: {
        adIdValue: '',
        state: '',
        BCID: '',
        timeRange: '',
        company: '',
      },
      // 所有账户列表
      allAccountList: [],
      total: 0,
      newArrayIds: [],
      SelectNumber: 0,
      // 是否预后付 1是预付 2后
      credit_typ: '',
      // 1代表渠道
      qudao: '',
      // 账户充值是否可点击标识
      balance: '',
      // 选中的list
      checkTableList: [],
      // 导出消耗弹数据
      downLoadDialogVisible: false,
      accountType: '1',
      expendTimeRange: '',
      expendValue: ['advertiser_id', 'spent_x'],
      expendData: [
        {
          key: 'name',
          label: '账户名称',
        },
        {
          key: 'advertiser_id',
          label: '账户ID',
          disabled: true,
        },
        {
          key: 'chinese_legal_entity_name',
          label: '营业执照',
        },
        {
          key: 'status',
          label: '账户状态',
        },
        {
          key: 'spent_x',
          label: '消耗',
          disabled: true,
        },
        {
          key: 'balance',
          label: '账户余额',
        },
      ],
      editNameDialogVisible: false,
      editNameForm: {
        accName: '',
      },
      editNameFormRules: {
        accName: [
          { required: true, message: '请输入新的账户名称', trigger: 'blur' },
          {
            pattern: /^[\u4E00-\u9FA5A-Za-z0-9-.]+$/,
            message: '新名称仅可包含中文、英文、数字和“-”，“.”等',
            trigger: 'blur',
          },
        ],
      },
      accId: '',
      oldName: '',
      // 代投客户不能充值
      fbServiceMode: '',
    }
  },
  mounted() {
    // 获取localStorage中的用户信息
    this.userInfo = this.$store.getters.userInfo
    this.getAllAccountList()
  },
  methods: {
    // 查询按钮
    async queryFormBtn() {
      this.$refs.accountFormRef.validate((valid) => {
        if (!valid) return
        this.getAllAccountList()
      })
    },
    // table需设置row-key
    getAccountId(account) {
      return account.account_id
    },
    // 重置按钮
    resetFormBtn() {
      this.$refs.accountFormRef.resetFields()
      this.getAllAccountList()
    },
    // 获取列表数据
    async getAllAccountList() {
      const { data: res } = await this.$http.post('cloudgg/ad_list', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        account_id: this.accountForm.adIdValue,
        // bc_id: this.accountForm.BCID,
        // account_status: this.accountForm.state,
        // timeRange: this.accountForm.timeRange,
        // chinese_legal_entity_name: this.accountForm.company
      })
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.allAccountList = res.advertising
      this.total = res.data.total
      this.credit_typ = res.data.credit_type
      this.qudao = res.data.qudao
      this.balance = res.data.balance
      this.fbServiceMode = res.data.fb_service_mode
      if (this.accountForm.timeRange == '') {
        this.accountForm.timeRange = res.data.timeRange
      }
      if (this.expendTimeRange == '') {
        this.expendTimeRange = res.data.timeRange
      }
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getAllAccountList()
    },
    // 展示点击分页触发的函数
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getAllAccountList()
    },
    handleSelectionChange(selection) {
      this.SelectNumber = selection.length
      this.checkTableList = selection
      const newArrIds = []
      selection.forEach((item) => {
        newArrIds.push(item.account_id)
      })
      this.newArrayIds = newArrIds
    },
    singleSelect(selection, row) { },
    selectAll() { },
    rowClick() { },
    // 余额清零
    async clearRemainder() {
      if (this.SelectNumber == 0) {
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }
      if (this.credit_typ == 1) {
        var msg = `你确定要将所选的${this.SelectNumber}个账户清零吗？请先确保该广告账户下所有广告已暂停6小时以上。`
      } else {
        var msg = `你确定要将所选的${this.SelectNumber}个账户清零吗？`
      }
      const confirmResult = await this.$confirm(`${msg}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)

      if (confirmResult !== 'confirm') {
        return
      } else {
        // 发起余额清零的请求
        const { data: res } = await this.$http.post('cloudgg/resets', {
          height: this.height,
          width: this.width,
          browser: this.browser,
          os: this.os,
          acc_ids: this.newArrayIds,
        })

        if (res.code == 300) {
          this.$router.push('/login')
        }
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }

        // 触发更新余额模块的金额
        this.$bus.emit('getUserBalance')

        // 余额清零点击确定后的返回信息弹
        this.$alert(`以下是批量清零情况:<br>${res.msg}`, '信息', {
          confirmButtonText: '收到',
          dangerouslyUseHTMLString: true,
          callback: (action) => {
            // this.$message({
            //   type: 'info',
            //   message: `action: ${action}`
            // })
            this.$refs.allAccountRef.clearSelection()
            // 清零后重新请求刷新列表
            this.getAllAccountList()
          },
        })
      }
    },

    // 账户充值
    async recharge() {
      if (this.SelectNumber == 0) {
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }

      if (this.balance <= 0) {
        return this.$message.error('您的可用余额不足，无法充值广告账户')
      }

      this.rechargeDialogVisible = true
    },
    rechargeFormClose() {
      this.rechargeDialogVisible = false
      this.$refs.rechargeFormRef.resetFields()
    },
    // 充值请求
    async handleRecharge() {
      let loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '正在充值中，请稍后...',
      })
      // 发起充值的请求
      const { data: res } = await this.$http.post('cloudgg/recharge_account', {
        height: this.height,
        width: this.width,
        browser: this.browser,
        os: this.os,
        money: parseInt(this.rechargeForm.money),
        acc_ids: this.newArrayIds,
      }).finally(() => {
        if (loading && loading.close) loading.close()
      })
      // 触发更新余额模块的金额
      this.$bus.emit('getUserBalance')

      // 充值点击确定后的返回信息弹框
      this.$alert(`以下是批量充值情况:<br>${res.msg}`, '信息', {
        confirmButtonText: '收到',
        dangerouslyUseHTMLString: true,
        callback: (action) => {
          this.$refs.allAccountRef.clearSelection()
          // 清零后重新请求刷新列表
          this.getAllAccountList()
        },
      })
    },
    // 充值对话框确定按钮
    rechargeSumBtn() {
      this.$refs.rechargeFormRef.validate(async (valid) => {
        if (!valid) return
        this.rechargeDialogVisible = false
        // 再次确认是否充值
        const confirmResult = await this.$confirm(
          `确定向所选的${this.SelectNumber}个广告账户中,每个都充值${this.rechargeForm.money}美金吗?`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).catch((err) => err)

        if (confirmResult !== 'confirm') return

        const rechargeHistory = await api.common.getRechargeHistory({
          acc_ids: this.newArrayIds,
          type: 1,
          money: this.rechargeForm.money,
          media_type: 1,
        })
        if (rechargeHistory.exa === 1) {
          // 24小时有同金额充值记录
          const confirmRecharge = await this.$confirm(
            `${rechargeHistory.account_id}账户24小时内有同金额充值记录,确定要继续充值吗?`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
          ).catch((err) => err)
          if (confirmRecharge !== 'confirm') return
          return this.handleRecharge()
        }
        this.handleRecharge()
      })
    },

    // BM解绑
    async BMUnbind() {
      if (this.SelectNumber == 0) {
        this.BMUnbindDialogVisible = false
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }
      this.BMUnbindDialogVisible = true
    },
    BMUnbindFormClose() {
      this.BMUnbindDialogVisible = false
      this.$refs.BMUnbindFormRef.resetFields()
    },
    // 解绑对话框确定按钮
    BMUnbindBtn() {
      this.$refs.BMUnbindFormRef.validate(async (valid) => {
        if (!valid) return
        this.BMUnbindDialogVisible = false
        // 再次确认是否解绑
        const confirmResult = await this.$confirm(
          `你确定要将所选的${this.SelectNumber}个广告账户,与${this.BMUnbindForm.unbindId}解绑吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).catch((err) => err)

        if (confirmResult !== 'confirm') {
          return
        } else {
          // 发起解绑的请求
          const { data: res } = await this.$http.post('cloudgg/set_bc', {
            height: this.height,
            width: this.width,
            browser: this.browser,
            os: this.os,
            acc_ids: this.newArrayIds,
            bc_id: this.BMUnbindForm.unbindId,
            type: 2,
          })

          if (res.code == 300) {
            this.$router.push('/login')
          }
          if (res.code == 100) {
            return this.$message.error(`${res.msg}`)
          }
          // 解绑点击确定后的返回信息弹框
          this.$alert(`以下是批量解绑情况:<br>${res.msg}`, '信息', {
            confirmButtonText: '收到',
            dangerouslyUseHTMLString: true,
            callback: (action) => {
              this.$refs.allAccountRef.clearSelection()
              // 解绑后重新请求刷新列表
              this.getAllAccountList()
            },
          })
        }
      })
    },

    // BM绑定
    async BMBind() {
      if (this.SelectNumber == 0) {
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }
      this.BMBindDialogVisible = true
    },
    BMBindFormClose() {
      this.BMBindDialogVisible = false
      this.$refs.BMBindFormRef.resetFields()
    },
    BMBindBtn() {
      this.$refs.BMBindFormRef.validate(async (valid) => {
        if (!valid) return
        this.BMBindDialogVisible = false
        // 再次确认是否解绑
        const confirmResult = await this.$confirm(
          `你确定要将所选的${this.SelectNumber}个广告账户,与${this.BMBindForm.bindId}绑定吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).catch((err) => err)

        if (confirmResult !== 'confirm') {
          return
        } else {
          // 发起绑定的请求
          const { data: res } = await this.$http.post('cloudgg/set_bc', {
            height: this.height,
            width: this.width,
            browser: this.browser,
            os: this.os,
            acc_ids: this.newArrayIds,
            bc_id: this.BMBindForm.bindId,
            type: 1,
          })

          if (res.code == 300) {
            this.$router.push('/login')
          }
          if (res.code == 100) {
            return this.$message.error(`${res.msg}`)
          }
          // 绑定点击确定后的返回信息弹框
          this.$alert(`以下是批量绑定情况:<br>${res.msg}`, '信息', {
            confirmButtonText: '收到',
            dangerouslyUseHTMLString: true,
            callback: (action) => {
              this.$refs.allAccountRef.clearSelection()
              // 绑定后重新请求刷新列表
              this.getAllAccountList()
            },
          })
        }
      })
    },
    blockedRow(row, index) {
      // if (row.account_status != 1) {
      //   return false
      // } else {
      return true
      // }
    },
    // 下载消耗数据
    downLoadExpend() {
      this.downLoadDialogVisible = true
    },
    downLoadFormBtn() {
      if (this.expendValue == '') {
        return this.$message.warning('请选择字段')
      }
      var expendValue = this.expendValue.join(',')
      var expendTimeRange = this.expendTimeRange.join(',')

      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '下载中，请稍后...',
      })

      this.$http({
        // 用axios发送post请求
        method: 'post',
        url: 'cloudgg/ad_export', // 请求地址
        data: {
          token: this.userInfo.token,
          timeRange: expendTimeRange,
          field: expendValue,
          account: this.accountType,
        },
        responseType: 'blob', // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          // 处理返回的文件流
          const content = res.data
          const blob = new Blob([content])
          const fileName = '账户消耗.xls'
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
        .finally(() => {
          loading.close()
        })
      this.downLoadDialogVisible = false
    },
    downLoadFormClose() {
      this.downLoadDialogVisible = false
    },
    async editName(row) {
      this.editNameDialogVisible = true
      this.accId = row.advertiser_id
      this.oldName = row.name
    },
    editNameBtn() {
      this.$refs.editNameFormRef.validate(async (valid) => {
        if (!valid) return
        this.editNameDialogVisible = false
        const confirmResult = await this.$confirm('你确定要更改账户名称吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).catch((err) => err)

        if (confirmResult !== 'confirm') {
          return
        } else {
          // 发起改名的请求
          const { data: res } = await this.$http.post('cloudgg/edit_account_name', {
            name: this.editNameForm.accName,
            id: this.accId,
          })

          if (res.code == 300) {
            this.$router.push('/login')
          }
          if (res.code == 100) {
            return this.$message.error(`${res.msg}`)
          }
          this.$message.success('修改成功')
          window.location.reload()
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.el-icon-edit-outline {
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
}

.downLoadRow {
  position: relative;

  #downLoad {
    position: absolute;
    right: 0;
  }
}

.timeRange {
  :deep(.el-date-editor.el-input__inner) {
    width: 220px !important;
  }
}

.downLoadForm {
  .el-checkbox {
    width: 100px;
  }

  :deep(.el-transfer__button) {
    display: block;
    min-height: 32px;
    padding: 9px 15px;
    font-size: 12px;
  }

  :deep(.el-transfer__button:nth-child(2)) {
    margin-left: 0 !important;
  }
}

.el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 20px;
}

:deep(.el-alert__icon) {
  background-color: #e6f7ff !important;
  color: #2b82ff !important;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}
</style>
