<template>
  <div>
    <el-row :gutter="20" class="firsrRow">
      <el-col :span="16">
        <AccountState :accountStateItem="accountStateItem"></AccountState>
      </el-col>

      <el-col :span="8">
        <BalanceCard type="facebook"></BalanceCard>
<!--        <usable-balance></usable-balance>-->
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-card>
          <el-tabs v-model="activeName" @tab-click="tabClick">
            <!-- 所有账户列表 -->
            <el-tab-pane label="所有账户" name="all">
              <template #label>
                <span>所有账户( {{ $filters.thousands(totalNum) }} )</span>
              </template>
              <AllAccount />
            </el-tab-pane>

            <!-- 被封账户列表 -->
            <el-tab-pane label="被封账户" name="blocked">
              <template #label>
                <span>被封账户( {{ $filters.thousands(blockedNum) }} )</span>
              </template>
              <BlockedAccount />
            </el-tab-pane>

            <!-- 闲置账户 -->
            <el-tab-pane label="闲置账户" name="used">
              <template #label>
                <span>闲置账户( {{ $filters.thousands(usedNum) }} )</span>
                <el-tooltip class="item" effect="light" content="" placement="top">
                  <template #content>
                    7天以上未产生消耗的账户
                  </template>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </template>
              <UsedAccount />
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AccountState from '../AccountState.vue'
import UsableBalance from '../UsableBalance.vue'
import AllAccount from './AllAccount.vue'
import BlockedAccount from './BlockedAccount.vue'
import UsedAccount from './UsedAccount.vue'
import BalanceCard from "@/views/account/common/BalanceCard";

export default {
  components: {
    BalanceCard,
    AccountState,
    UsableBalance,
    AllAccount,
    BlockedAccount,
    UsedAccount
  },
  name: 'UserAccountList',
  data() {
    return {
      userInfo: '',
      activeRate: '',
      activeName: 'all',
      salesmanToken: '',
      totalNum: '',
      blockedNum: '',
      usedNum: '',
      isGetData: false,
      accountStateItem: {}
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getAllAccountList()
    this.updateType()
  },
  computed: {},
  methods: {
    // 获取列表数据
    async getAllAccountList() {
      const { data: res } = await this.$http.post('cloudfb/ad_data', {
        token: this.userInfo.token
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
     
      this.accountStateItem = res.data.accountStateItem
      this.salesmanToken = res.data.salesman.token
      this.totalNum = res.data.total_num
      this.blockedNum = res.data.blocked_num
      this.usedNum = res.data.idle_num
      this.isGetData = true
    },
    updateType() {
      let type = this.$route.query.type
      // 判断type的值，更改activeName的值
      if (type === 'all') {
        this.activeName = 'all'
      } else if (type === 'blocked') {
        this.activeName = 'blocked'
      } else if (type === 'used') {
        this.activeName = 'used'
      }
    },
    tabClick(){
      this.$bus.emit('closeAccountsDialog')
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.el-tabs__header) {
  margin-bottom: 30px;
}
.firsrRow {
  margin-bottom: 20px;

  .accountNum .el-row {
    margin-bottom: 10px;
  }
}
i.el-icon-warning {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}
:deep(.el-tabs__item) {
  font-size: 16px !important;
}
:deep(.el-dialog__header) {
  padding: 15px 20px !important;
  border-bottom: 1px solid #ebebeb;
}
i.el-icon-warning-outline {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
  margin-left: 8px;
}
</style>
