<template>
  <el-dialog v-model="dialogShow" width="30%" :modal="!tempHide" :append-to-body="tempHide"
    :custom-class="tempHide ? 'hidden dialog-hidden' : ''" title="账户充值" @close="$emit('close', '')">
    <el-form ref="formRef" :model="form" :rules="rules" label-width="60px" @submit.native.prevent>
      <el-form-item label="金额" prop="money">
        <el-input size="small" placeholder="请输入充值金额$" clearable v-model="form.money"
          @keyup.enter.native="submit"></el-input>
        <span class="Text">可用余额：{{ balance.total_balance }}美元</span>
      </el-form-item>
      <el-form-item label="预览">
        已选择 <strong class="color-primary">{{ actIds.length }}</strong> 个账户，预计扣款 <strong class="color-primary">{{
          actIds.length * form.money }}</strong> 美元
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </span>
    </template>

  </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";
import { ElMessage, ElLoading, ElMessageBox } from "element-plus";
import base from "@/base/filters/base";
import api from '@/request/api/account'

const { proxy } = getCurrentInstance()
let props = defineProps({
  actIds: { required: true },
  balance: { required: true },
  recharge: { type: Function }
})
let emit = defineEmits(['refresh'])
let tempHide = ref(false)

let dialogShow = ref(true)
let formRef = ref()
let form = ref({
  height: window.innerHeight,
  width: window.innerWidth,
  browser: base.getBrowserInfo().browserName + '的版本是：' + base.getBrowserInfo().versionInfo,
  os: base.getOsInfo().name === 'Windows' ? base.getOsInfo().version : base.getOsInfo().name,
  money: 0,
  acc_ids: props.actIds
})

let rules = {
  money: [
    { required: true, message: '请输入正确的金额', trigger: 'blur' },
    {
      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
      message: '充值金额错误，请重新输入',
      trigger: 'blur'
    },
    {
      validator: (rule, value, cb) => {
        if (value * props.actIds.length > props.balance.total_balance) {
          return cb(new Error('预计扣款金额大于可用余额，请重新分配'))
        }

        return cb()
      }, trigger: 'change'
    }
  ]
}
const handleRecharge = async () => {
  let loading = ElLoading.service({
    spinner: 'el-icon-loading',
    lock: true,
    text: '正在充值中，请稍后...'
  })

  let res = await props.recharge(form.value).finally(() => {
    loading.close()
  })

  // 充值点击确定后的返回信息弹框
  await ElMessageBox.alert(`以下是批量充值情况:<br>${res.msg}`, '信息', {
    confirmButtonText: '收到',
    dangerouslyUseHTMLString: true,
    callback: () => {
      emit('refresh')
    }
  })
}
let submit = async () => {
  if (!props.recharge) return ElMessage.error('Invalid Recharge Function')
  const result = await formRef.value.validate()
  if (true !== result) return
  tempHide.value = true

  let confirm = await ElMessageBox.confirm(
    `确定向所选的 ${form.value.acc_ids.length} 个广告账户中，每个都充值 ${form.value.money} 美金吗?`,
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).catch(err => err)

  if (confirm !== 'confirm') {
    return tempHide.value = false
  }
  const rechargeHistory = await api.common.getRechargeHistory({
    acc_ids: form.value.acc_ids,
    type: 1,
    money: form.value.money,
    media_type: 1,
  })
  if (rechargeHistory.exa === 1) {
    // 24小时有同金额充值记录
    const confirmRecharge = await proxy.$confirm(
      `${rechargeHistory.account_id}账户24小时内有同金额充值记录,确定要继续充值吗?`,
      '提示',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).catch((err) => err)
    if (confirmRecharge !== 'confirm') return
    return handleRecharge()
  }
  handleRecharge()
}
</script>

<style scoped></style>