<template>
  <el-row>
    <el-col>
      <el-card class="box-card" style="min-height: 800px">
        <template #header>
          <div class="card-header">
            <span class="SubHead">开户申请</span>
            <div class="back" v-if="dialogType === 'bing'" @click="dialogType = ''">返回</div>
            <!-- <router-link to="subMsg" v-else><span class="LinkText" id="openHistory">订阅开户通知</span></router-link> -->
          </div>
        </template>
        <apply-bing-account v-if="dialogType === 'bing'" />
        <template v-else>
          <el-row :gutter="10" style="margin-bottom: 20px">
            <el-col :span="12">
              <el-card shadow="hover" style="padding: 40px 0">
                <el-row>
                  <el-col :span="16" class="FBCol">
                    <h3 class="MainSmallHead">Meta</h3>
                    <div class="FBEcology">
                      <img src="@/assets/fb_icon1.png" alt="" />
                      <img src="@/assets/fb_icon2.png" alt="" />
                      <img src="@/assets/fb_icon3.png" alt="" />
                      <img src="@/assets/fb_icon4.png" alt="" />
                    </div>
                    <ul class="FBSlogan">
                      <li class="Text">仅需准备营业执照、推广链接即可快速完成开户</li>
                      <li class="Text">1分钟填写表单，最快当天下户！</li>
                    </ul>
                  </el-col>
                  <el-col :span="8" class="FBCol" style="border-left: 1px solid #e4e7ed; justify-content: space-around">
                    <img src="@/assets/meta_logo.png" class="MetaLogo" alt="" />
                    <el-button type="primary" size="small" @click="openFB">申请广告账户</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card shadow="hover" style="padding: 40px 0">
                <el-row>
                  <el-col :span="16" class="TTCol">
                    <h3 class="MainSmallHead">TikTok for Business</h3>
                    <div class="TTEcology">
                      <img src="@/assets/tt_icons.png" alt="" />
                    </div>
                    <ul class="TTSlogan">
                      <li class="Text">仅需准备营业执照、推广链接即可快速完成开户</li>
                      <li class="Text">1分钟填写表单，最快当天下户！</li>
                    </ul>
                  </el-col>
                  <el-col :span="8" class="TTCol" style="border-left: 1px solid #e4e7ed; justify-content: space-around">
                    <img src="@/assets/tt_logo.png" class="TTLogo" alt="" />
                    <el-button type="primary" size="small" @click="openTT">申请广告账户</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>

          <el-row :gutter="10" style="margin-bottom: 20px">
            <!--             Google开户-->
            <el-col :span="12">
              <el-card shadow="hover" style="padding: 40px 0">
                <el-row>
                  <el-col :span="16" class="TTCol">
                    <h3 class="MainSmallHead">Google</h3>
                    <div class="GGEcology">
                      <img src="@/assets/images/G-AC.png" alt="" />
                      <img src="@/assets/images/G-mail.png" alt="" />
                      <img src="@/assets/images/G-Maps.png" alt="" />
                      <img src="@/assets/images/G-shopping.png" alt="" />
                      <img src="@/assets/images/G-YouTube.png" alt="" />
                    </div>
                    <ul class="TTSlogan">
                      <li class="Text">仅需准备营业执照、推广链接即可快速完成开户</li>
                      <li class="Text">1分钟填写表单，最快当天下户！</li>
                    </ul>
                  </el-col>
                  <el-col :span="8" class="TTCol" style="border-left: 1px solid #e4e7ed; justify-content: space-around">
                    <img src="@/assets/images/G-logo.png" class="TTLogo" alt="" />
                    <el-button type="primary" size="small" @click="dialogType = 'gg'">申请广告账户</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <!-- 微软广告开户-->
            <el-col :span="12">
              <el-card shadow="hover" style="padding: 40px 0">
                <el-row>
                  <el-col :span="16" class="TTCol">
                    <h3 class="MainSmallHead">Microsoft Advertising</h3>
                    <div class="GGEcology">
                      <img src="@/assets/bing_edge.png" alt="" />
                      <img src="@/assets/bing_bing.png" alt="" />
                      <img src="@/assets/bing_msn.png" alt="" />
                      <img src="@/assets/bing_outlook.png" alt="" />
                      <img src="@/assets/bing_xbox.png" alt="" />
                      <img src="@/assets/bing_in.png" alt="" />
                    </div>
                    <ul class="TTSlogan">
                      <li class="Text">全球第二大搜索引擎必应，拥有超过6亿高购买力用户</li>
                      <li class="Text">1分钟填写表单，最快当天下户！</li>
                    </ul>
                  </el-col>
                  <el-col :span="8" class="TTCol" style="border-left: 1px solid #e4e7ed; justify-content: space-around">
                    <img src="@/assets/bing_logo.png" class="TTLogo" alt="" />
                    <el-button type="primary" size="small" @click="dialogType = 'bing'">申请广告账户</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-row>
            <!-- 更多 -->
            <el-col :span="12">
              <el-card shadow="hover" style="padding: 40px 0; min-height: 232px">
                <el-row>
                  <el-col :span="24" class="TTCol">
                    <i class="el-icon-more" style="margin: 60px 0"></i>
                    <el-button type="primary" size="small">敬请期待更多</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </template>
      </el-card>
    </el-col>
  </el-row>

  <!-- FB营业执照校验对话框 -->
  <OpenAccount v-if="'fb' === dialogType" @close="dialogType = ''" />
  <!-- TT开户流程对话框 -->
  <TTOpenAccount v-if="'tt' === dialogType" @close="dialogType = ''" />
  <!-- GG开户流程对话框 -->
  <GGNewOpen v-if="'gg' === dialogType" @close="dialogType = ''"></GGNewOpen>
</template>

<script>
import TTOpenAccount from '../tiktok/users/account/TTOpenAccount.vue'
import OpenAccount from '../users/accountList/OpenAccount.vue'
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import $ from 'jquery'
import ApplyBingAccount from '@/views/account/manage/bing/ApplyAccount'
import GGNewOpen from '@/components/accountManage/google/GGNewOpen'

export default {
  name: 'AccountManage',
  components: { GGNewOpen, TTOpenAccount, OpenAccount, ApplyBingAccount },
  data() {
    return {
      userInfo: '',
      activeRate: '',
      FBOpenStatus: '',
      FBOpenMsg: '',
      driverOpen: null,
      intro: '',
      taskNum: 0,
      intention_level: '',
      dialogType: '',
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getActiveRate()
    this.getNewTask()
  },
  beforeUnmount() {
    if (this.driverOpen != null) {
      this.driverOpen.reset()
    }
  },
  methods: {
    async getNewTask() {
      const { data: res } = await this.$http.post('user/novice_task')
      this.taskNum = res.num
    },
    async getActiveRate() {
      const { data: res } = await this.$http.post('cloudfb/ad_data')
      if (res.code == 300) return this.$router.push('/login')
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      this.activeRate = res.data.accountStateItem.active_rate
      this.FBOpenStatus = res.data.usage_status
      this.FBOpenMsg = res.data.usage_msg
      this.intention_level = res.data.intention_level
      this.intro = res.data.intro
      if (this.intro == 1 && this.taskNum == 6) {
        this.openGuide()
      }
    },
    openFB() {
      if (this.FBOpenStatus != 1) {
        return this.$message.warning(`${this.FBOpenMsg}`)
      }
      this.dialogType = 'fb'
      // this.$bus.emit('openCreateFBDialog')
    },
    openTT() {
      this.dialogType = 'tt'
    },
    AddScroll() {
      var top = $('.el-main').scrollTop()
      $('.el-main').on('scroll.unable', function (e) {
        $('.el-main').scrollTop(top)
      })
    },
    RemoveScroll() {
      $('.el-main').unbind('scroll.unable')
    },
    openGuide() {
      this.$nextTick(() => {
        this.driverOpen = new Driver({
          allowClose: false,
          opacity: 0,
          nextBtnText: '下一步',
          closeBtnText: '完成',
          onReset: () => {
            this.RemoveScroll()
            this.$http.post('user/set_intro', {
              type: 'account_open',
            })
          },
          onHighlightStarted: () => {
            this.$nextTick(() => {
              this.AddScroll()
            })
          },
          onNext: () => {
            this.RemoveScroll()
          },
        })

        this.driverOpen.highlight({
          element: '#openHistory',
          popover: {
            title: '新功能指引',
            description: 'Facebook、TikTok开户历史已经合并，查看开户历史更方便',
            position: 'left',
          },
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .back {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2b82ff;
    cursor: pointer;
  }
}

.FBEcology,
.GGEcology {
  margin: 4px 0;

  img {
    width: 32px;
    height: auto;
    margin-right: 20px;
  }
}

.FBSlogan,
.TTSlogan {
  width: 100%;
  line-height: 1.5;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    width: 315px;
    line-height: 1.5;
    list-style-type: disc;
    list-style-position: inside;
    white-space: nowrap;
  }
}

.el-card.is-hover-shadow:hover {
  border-color: #2b82ff;
}

.FBCol,
.TTCol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MetaLogo,
.TTLogo {
  width: 130px;
  height: auto;
}

.MetaLogo {
  margin: 15px 0;
}

.TTEcology {
  img {
    width: auto;
    height: 40px;
  }
}
</style>
