const validator = {
  urlValidRule: (rule, value, cb) => {
    let reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/

    if (typeof value === 'string') {
      if (!reg.test(value)) {
        cb(new Error(rule.message ? rule.message : '推广链接格式错误，需带http或者https'))
      }
    } else {
      value.forEach(item => {
        if (!reg.test(item)) {
          cb(new Error(rule.message ? rule.message : '推广链接格式错误，需带http或者https'))
        }
      })
    }
    return cb()
  },
  validCharactor: (rule, value, cb) => {
    let realLength = 0, len = value.length, charCode = -1;
    for (let i = 0; i < len; i++) {
      charCode = value.charCodeAt(i);
      if (charCode >= 0 && charCode <= 128) realLength += 1;
      else realLength += 2;
    }
    if (rule.max && realLength > rule.max) {
      cb(new Error(rule.message ? rule.message : `最长不能超过${rule.max}个字符`))
    }

    return cb()
  }
}

export default validator
