<template>
  <el-form ref="accountFormRef" :inline="true" :model="accountForm">
    <el-row>
      <el-form-item label="广告账户" prop="account_id">
        <el-input placeholder="请输入广告账户ID或账户名称" size="small" clearable v-model.trim="accountForm.account_id"
          style="width: 220px"></el-input>
      </el-form-item>
      <el-form-item label="BMID" prop="BMID">
        <el-input placeholder="请输入BMID" size="small" clearable v-model="accountForm.BMID"></el-input>
      </el-form-item>
      <el-form-item label="被封类别" prop="blockedType">
        <el-select v-model="accountForm.blockedType" placeholder="请选择" size="small" clearable>
          <el-option label="DRR" value="1"></el-option>
          <el-option label="未知" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="被封时间" prop="timeRange">
        <el-date-picker type="daterange" v-model="accountForm.timeRange" size="small" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="X" />
      </el-form-item>
      <el-form-item style="margin-left: 14px">
        <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
        <el-button @click="resetFormBtn" size="small">重置</el-button>
      </el-form-item>
    </el-row>
  </el-form>

  <el-alert title="提示" description="" type="info" show-icon>
    <template #default>
      如需要绑定像素请在问题反馈提交需求工单，我们会在工作时间及时为您处理。格式：像素ID：xxx
      分享到BMID：xxx或账户ID：xxx。<br />
      若您需要对多个账户以及被封账户同时进行批量操作,请点击<span class="articleLink" @click="HandleAccounts">多账户批量操作</span>
    </template>
  </el-alert>

  <el-table :data="BlockedAccountList" style="width: 100%" :row-key="getAccountId" :header-cell-style="{
    background: 'var(--el-table-row-hover-background-color)',
  }">
    <el-table-column prop="name" :label="qudao == 1 ? '营业执照' : '账户名称'" min-width="200" />
    <el-table-column prop="account_id" label="账户ID" min-width="150" />
    <el-table-column prop="account_status" label="账号状态">
      <template #default="{ row }">
        <el-tag type="danger" v-if="row.account_status != 1">被封</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="is_drr">
      <template #header>
        <span>被封类别</span>
        <el-tooltip class="item" effect="light" content="" placement="top">
          <template #content>
            被封类别显示DRR，即为该账户因违反Facebook<br />相关广告政策被封停，请前往Facebook广告管理<br />工具查看详情，进行申诉；被封类别显示未知，<br />一般情况下是因为闲置时间过长，被
            Facebook封停。
          </template>
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </template>
      <template #default="{ row }">
        <el-tag type="info" v-if="row.is_drr == 1">DRR</el-tag>
        <el-tag type="info" v-else>未知</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="revenue" label="被封消耗">
      <template #default="{ row }"> {{ $filters.thousands(row.revenue) }}</template>
    </el-table-column>
    <el-table-column property="disable_time" label="被封时间" min-width="120" />
    <el-table-column property="msg" label="被封原因" width="230" />
    <template #empty>
      <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
    </template>
  </el-table>
  <el-row>
    <!-- <el-col :span="12" style="padding-top: 15px;">
      <el-button size="mini" @click="BMUnbind">BM解绑</el-button>
      <span class="Text" style="margin-left:15px;"
        >已选择{{ SelectNumber }}个</span
      >
    </el-col> -->
    <el-col style="text-align: right">
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
        :page-sizes="[2, 5, 10, 20, 50]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total" background>
      </el-pagination>
    </el-col>
  </el-row>

  <!-- 操作多账户 -->
  <HandleAccountsDialog v-if="isDialogShow" @close="isDialogShow = false" />
</template>

<script>
import HandleAccountsDialog from './HandleAccountsDialog.vue'
import base from '../../../request/base'

export default {
  name: 'BlockedAccount',
  components: {
    HandleAccountsDialog,
  },
  data() {
    return {
      base: base,
      userInfo: '',
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5,
      },
      accountForm: {
        account_id: '',
        BMID: '',
        blockedType: '',
        timeRange: [],
      },
      total: 0,
      // 被封账户列表
      BlockedAccountList: [],
      qudao: '',
      isDialogShow: false,
    }
  },
  mounted() {
    // 获取localStorage中的用户信息
    this.userInfo = this.$store.getters.userInfo
    this.getBlockedAccountList()
  },
  methods: {
    // 查询按钮
    queryFormBtn() {
      this.$refs.accountFormRef.validate((valid) => {
        if (!valid) return
        this.getBlockedAccountList()
      })
    },
    // 重置按钮
    resetFormBtn() {
      this.$refs.accountFormRef.resetFields()
      this.getBlockedAccountList()
    },
    // table需设置row-key
    getAccountId(account) {
      return account.bm_id
    },
    // 获取列表数据
    async getBlockedAccountList() {
      const { data: res } = await this.$http.post('cloudfb/blocked_list', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        account_id: this.accountForm.account_id,
        bm_id: this.accountForm.BMID,
        blocked_type: this.accountForm.blockedType,
        timeRange: this.accountForm.timeRange,
      })
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.BlockedAccountList = res.advertising
      this.total = res.data.total
      this.qudao = res.data.qudao
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getBlockedAccountList()
    },
    // 展示分页选中触发的函数
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getBlockedAccountList()
    },
    HandleAccounts() {
      this.isDialogShow = true
    },
  },
}
</script>

<style lang="less" scoped>
.el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 20px;
}

:deep(.el-alert__icon) {
  background-color: #e6f7ff !important;
  color: #2b82ff !important;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}

.articleLink {
  color: #2b82ff;
  text-decoration: none;
  cursor: pointer;
}
</style>
