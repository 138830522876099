<template>
  <div style="position:relative;">
    <el-tabs v-model="activeName" class="listTabs">
      <el-tab-pane label="" name="facebook">
        <template #label>
          <span id="firstStep">Facebook</span>
        </template>
        <UserAccountList v-if="activeName === 'facebook'"/>
      </el-tab-pane>
      <el-tab-pane label="TikTok" name="tiktok">
        <TTAccountList v-if="activeName === 'tiktok'"/>
      </el-tab-pane>
      <el-tab-pane label="微软广告" name="bing">
        <BingAccountList v-if="activeName === 'bing'"/>
      </el-tab-pane>
      <el-tab-pane label="Google" name="google">
        <GGAccountList v-if="activeName === 'google'"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TTAccountList from '../tiktok/users/account/TTAccountList.vue'
import UserAccountList from '../users/accountList/UserAccountList.vue'
import GGAccountList from '../accountManage/google/GGAccountList.vue'
import BingAccountList from '@/views/account/manage/bing/Index'

export default {
  name: 'ListAccount',
  components: {
    TTAccountList,
    UserAccountList,
    GGAccountList,
    BingAccountList
  },
  props: ['tabsType'],
  data() {
    return {
      activeName: ''
    }
  },
  methods: {},
  mounted() {
    if (this.tabsType === 'tiktok') {
      this.activeName = 'tiktok'
    } else if (this.tabsType === 'bing') {
      this.activeName = 'bing'
    } else if (this.tabsType === 'google') {
      this.activeName = 'google'
    } else {
      this.activeName = 'facebook'
    }
  },
}
</script>

<style lang="less" scoped>
.listTabs {
  :deep(.el-tabs__header) {
    background: #fff;

    .el-tabs__nav-wrap {
      padding-left: 20px;
    }
  }
}
</style>
