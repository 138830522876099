<template>
  <el-dialog v-model="dialogShow"
             :title="title"
             width="790px"
             @close="$emit('close', '')"
             custom-class="download-bing-account">
    <el-form ref="formRef"
             label-width="100px"
             :model="formItems"
             :rules="rules">
      <el-form-item label="选择时间" prop="timeRange">
        <el-date-picker
            v-model="formItems.timeRange"
            type="daterange"
            size="small"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="X"
            :disabled-date="disabledDate"/>
      </el-form-item>
      <el-form-item label="选择账户">
        <el-radio v-model="formItems.account" :label="1" size="small">所有账户</el-radio>
        <el-radio v-model="formItems.account" :label="2" size="small">有消耗的账户</el-radio>
      </el-form-item>
      <el-form-item label="选择字段" prop="field">
        <el-transfer
            v-model="formItems.field"
            :left-default-checked="defaultCheckedFields"
            :right-default-checked="[]"
            :titles="['可选字段', '已选字段']"
            :button-texts="['移除', '添加']"
            :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}'
          }"
            :data="allFields"
        >
        </el-transfer>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="downloadFile">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {ref} from "vue";
import base from "@/base/filters/base";
import api from "@/request/api/account";
import {ElLoading} from "element-plus";

defineProps({
  title: {type: String, default: '下载消耗'},
  conditions: {type: Object}
})

let formRef = ref()
let dialogShow = ref(true)
let formItems = ref({
  timeRange: base.getDefaultDuration(),
  field: ['name', 'account_id'],
  account: 1
})

let rules = {
  timeRange: {required: true, message: '请选择时间', trigger: 'blur'},
  fields: {required: true, message: '请选择字段', trigger: 'blur'},
}

let defaultCheckedFields = [
  'name',
  'account_id',
  'number',
  'company',
  'c_id',
  'c_number',
  'account_status',
  'spent',
  'balance'
]

let allFields = [
  {key: 'name', label: 'XID账户名称', disabled: true},
  {key: 'account_id', label: 'XID', disabled: true},
  {key: 'number', label: 'Number',},
  {key: 'company', label: '营业执照'},
  {key: 'c_id', label: 'CID',},
  {key: 'c_number', label: 'CNumber',},
  {key: 'account_status', label: '账户状态'},
  {key: 'spent', label: '消耗'},
  {key: 'balance', label: '账户余额'}
]

let disabledDate = time => {
  if (formItems.value.timeRange && formItems.value.timeRange[0]) {
    return (
        time.getTime() > Date.now() - 3600 * 1000 * 24 ||
        time.getTime() < 1640966400 * 1000
    )
  } else {
    return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now() - 3600 * 1000 * 24
  }
}

let downloadFile = async () => {
  const valid = await formRef.value.validate()
  if (true !== valid) return null
  let loading = ElLoading.service({
    spinner: 'el-icon-loading',
    lock: true,
    text: '下载中，请稍后...'
  })

  let result = await api.bing.download(formItems.value).finally(() => {
    loading.close()
  })

  let blob = new Blob([result])
  let fileName = '账户消耗.xls'
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName)
  }
  dialogShow.value = false
}
</script>

<style>
.download-bing-account .el-transfer__button {
  display: block;
  min-height: 32px;
  padding: 9px 15px;
  font-size: 12px;
}

.download-bing-account .el-transfer__button:nth-child(2) {
  margin-left: 0 !important;
}
</style>