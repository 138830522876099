<template>
  <el-card class="box-card accountNum">
    <template #header>
      <div class="card-header">
        <span class="SubHead">{{ accountStateItem.title }}</span>
        <span>
          <el-button
            class="LinkText routerBtn"
            type="text"
            v-if="accountStateItem.active_rate >= 0"
            @click="dialogShow=true"
            >新增广告账号
          </el-button>
          <el-button class="routerBtn" type="text" disabled v-else>新增广告账号</el-button>
        </span>
      </div>
    </template>

    <div class="text item">
      <ul>
        <el-row :gutter="20">
          <el-col :span="6" v-for="item in accountStateItem.accountState" :key="item.id">
            <li class="accountList">
              <p class="Text">
                {{ item.title }}
                <span v-show="accountStateItem.isshow">
                  <el-tooltip class="item" effect="light" content="" placement="top">
                    <template #content>
                      {{ item.tips }}
                    </template>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </span>
              </p>
              <p class="MainHead">{{ item.total }}</p>
            </li>
          </el-col>
        </el-row>
      </ul>
    </div>
  </el-card>

  <!-- TT开户流程对话框 -->
  <!-- <TTOpenAccount :dialogShow="dialogShow" @handleDialog="handleDialog"></TTOpenAccount> -->
  <TTOpenAccount v-if="dialogShow === true" @close="dialogShow = false"/>
</template>

<script>
import TTOpenAccount from './TTOpenAccount.vue'
export default {
  name: 'TTAccountState',
  components: { TTOpenAccount },
  props: ['accountStateItem'],
  data() {
    // 浏览器高度
    // var height = window.innerHeight
    // 浏览器宽度
    // var width = window.innerWidth
    // 浏览器
    // 浏览器检查结果
    // let browserInfo = getBrowserInfo()
    // var browser = browserInfo.browserName + '的版本是：' + browserInfo.versionInfo
    // function getBrowserInfo() {
    //   let sysObj = {}
    //   let browserInfo = navigator.userAgent.toLowerCase()
    //   let regular = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
    //   let vsMsg = browserInfo.match(regular)
    //   sysObj.browserName = vsMsg[1].replace(/version/, "'safari")
    //   sysObj.versionInfo = vsMsg[2]
    //   return sysObj
    // }
    // 获取操作系统信息
    // function getOsInfo() {
    //   var userAgent = navigator.userAgent.toLowerCase()
    //   var name = 'Unknown'
    //   var version = 'Unknown'
    //   if (userAgent.indexOf('win') > -1) {
    //     name = 'Windows'
    //     if (userAgent.indexOf('windows nt 5.0') > -1) {
    //       version = 'Windows 2000'
    //     } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
    //       version = 'Windows XP'
    //     } else if (userAgent.indexOf('windows nt 6.0') > -1) {
    //       version = 'Windows Vista'
    //     } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
    //       version = 'Windows 7'
    //     } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
    //       version = 'Windows 8'
    //     } else if (userAgent.indexOf('windows nt 6.3') > -1) {
    //       version = 'Windows 8.1'
    //     } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
    //       version = 'Windows 10'
    //     } else {
    //       version = 'Unknown'
    //     }
    //   } else if (userAgent.indexOf('iphone') > -1) {
    //     name = 'Iphone'
    //   } else if (userAgent.indexOf('mac') > -1) {
    //     name = 'Mac'
    //   } else if (
    //     userAgent.indexOf('x11') > -1 ||
    //     userAgent.indexOf('unix') > -1 ||
    //     userAgent.indexOf('sunname') > -1 ||
    //     userAgent.indexOf('bsd') > -1
    //   ) {
    //     name = 'Unix'
    //   } else if (userAgent.indexOf('linux') > -1) {
    //     if (userAgent.indexOf('android') > -1) {
    //       name = 'Android'
    //     } else {
    //       name = 'Linux'
    //     }
    //   } else {
    //     name = 'Unknown'
    //   }
    //   return { name, version }
    // }
    // var os = getOsInfo().name
    // if (os == 'Windows') {
    //   os = getOsInfo().version
    // }

    return {
      // 客户端信息
      // width: width,
      // height: height,
      // browser: browser,
      // os: os,
      userInfo: '',
      dialogShow: false,
      dialogType: ''
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
  }
}
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.routerBtn {
  padding: 0;
  min-height: auto;
}
.box-card {
  height: 180px;
}
.accountList p {
  margin: 10px 0;
}
.text {
  height: 80px;
}

i.el-icon-warning-outline {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}
</style>
