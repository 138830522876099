import axios from '@/request/http'
import base from '@/request/base'
import QS from 'querystring'

const facebook = {
  // 多账户操作
  accountOperate(params) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/accounts_operate`, params)
  },
  // 获取被封账户
  getBlockedAccount(params) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/get_blocked_account`, params)
  },
  // 余额转移
  handleAccountTransfer(params) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/account_transfer`, params)
  },
  // 获取账户余额
  getAccountBalance(params) {
    return axios.get(`${base.admin_url}/cloud/cloudfb/refresh_balance`, { params: params })
  },
  // 余额减款
  handleAccountDeduction(params) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/credit_fb_reduce`, params)
  },
  // 查询是否有未暂停的账户列表
  getPauseAccount(params) {
    return axios.get(`${base.admin_url}/cloud/cloudfb/pause_fb_campaign`, { params: params })
  },
}
const tt = {
  getAccountData() {
    return axios.post(`${base.admin_url}/cloud/cloudtt/account_data`)
  },
}
const bing = {
  prepareApplyData() {
    return axios.get(`${base.admin_url}/cloud/cloudbing/account_data`)
  },
  applyAccount(params) {
    return axios.post(`${base.admin_url}/cloud/cloudbing/open_account`, params)
  },
  getHistory(params) {
    return axios.get(`${base.admin_url}/cloud/cloudbing/account_history`, { params: params })
  },
  getAccountList(params) {
    return axios.get(`${base.admin_url}/cloud/cloudbing/ad_list`, { params: params })
  },
  getAccountInfo() {
    return axios.get(`${base.admin_url}/cloud/cloudbing/ad_data`)
  },
  cIdList(params) {
    return axios.get(`${base.admin_url}/cloud/cloudbing/get_cid`, { params: params })
  },
  download(params) {
    return axios.post(
      `${base.admin_url}/cloud/cloudbing/ad_export`,
      {
        timeRange: params.timeRange && params.timeRange.length > 0 ? params.timeRange.join(',') : '',
        field: params.field && params.field.length > 0 ? params.field.join(',') : '',
        account: params.account,
      },
      {
        responseType: 'blob',
      }
    )
  },
  rechargeAccount(params) {
    return axios.post(`${base.admin_url}/cloud/cloudbing/recharge_account`, params)
  },
  resetAccount(params) {
    return axios.post(`${base.admin_url}/cloud/cloudbing/resets`, params)
  },
  dashboard() {
    return axios.get(`${base.admin_url}/cloud/cloudbing/dashboard`)
  },
  getDashboardDaily(params) {
    return axios.get(`${base.admin_url}/cloud/cloudbing/get_dashboard`, { params: params })
  },
}

const google = {
  dashboard() {
    return axios.get(`${base.admin_url}/cloud/cloudgg/dashboard`)
  },
  getDashboardDaily(params) {
    return axios.get(`${base.admin_url}/cloud/cloudgg/get_dashboard`, { params: params })
  },
}

const common = {
  getBalanceInfo(type) {
    return axios.get(`${base.admin_url}/cloud/cloudfb/ad_money`, { params: { type: type } })
  },
  refreshBalanceInfo(type) {
    return axios.post(`${base.admin_url}/cloud/cloudfb/refresh_balance`, { type: type })
  },
  // 24小时内同金额的充值查询
  getRechargeHistory(params) {
    return axios.get(`${base.admin_url}/cloud/cloudfb/recharge_account_exa`, { params: params })
  },
}

export default { facebook, tt, bing, google, common }
