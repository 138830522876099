<template>
  <el-card class="box-card accountNum">
    <template #header>
      <div class="card-header">
        <span class="SubHead">
          可用余额
          <el-tooltip class="item" effect="light" content="" placement="top">
            <template #content>
              可用余额：扣除掉账户余额后，还可以用于账户充值的金额；<br />
              账户余额：当前账户状态为正常的账户，账户余额的总和。
            </template>
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </span>
      </div>
    </template>

    <div class="text item">
      <el-row>
        <span class="Text" :class="numType == 1 ? 'red' : ''">可用余额：${{ $filters.thousands(balance) }}</span>
      </el-row>
      <el-row>
        <span class="Text" :class="ccType == 1 ? 'red' : ''">账户余额：${{ $filters.thousands(accountBalance) }}</span>
      </el-row>
      <!-- this.credit_type = 2的时候显示 -->
      <el-row>
        <span class="Text" v-show="creditType == 2">授信额度：${{ $filters.thousands(creditBalance) }} </span>
      </el-row>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'GGUsableBalance',
  props: [],
  data() {
    return {
      balance: '',
      accountBalance: '',
      creditBalance: '',
      creditType: '',
      //控制可用余额变红
      numType: '',
      //控制账户余额变红
      ccType: '',
      //控制账户余额弹窗
      cType: '',
      //控制可用余额弹窗
      nType: '',
      idleBalance: ''
    }
  },
  mounted() {
    this.getUserBalance()
    this.$bus.on('getUserBalance', this.getUserBalance)
  },
  methods: {
    async getUserBalance() {
      const { data: res } = await this.$http.post('cloudgg/ad_money')
      this.balance = res.data.balance
      this.accountBalance = res.data.count
      this.creditBalance = res.data.credit
      this.creditType = res.data.credit_type
      this.numType = res.data.num_type
      this.ccType = res.data.cc_type
      this.cType = res.data.c_type
      this.nType = res.data.n_type
      this.accountBalancePop(this.cType)
      this.balancePop(this.nType)
    },
    // 控制账户余额弹窗
    accountBalancePop(cType) {
      if (cType == 1) {
        this.$alert(
          `当前账户余额过多，其中闲置账户占用${this.idleBalance}的余额，可前往账户列表进行批量处理。`,
          '信息',
          {
            confirmButtonText: '收到',
            dangerouslyUseHTMLString: true
          }
        )
      }
    },
    // 控制可用余额弹窗
    balancePop(nType) {
      if (nType == 1) {
        this.$alert(`您的可用余额不足,请及时充值！`, '信息', {
          confirmButtonText: '收到',
          dangerouslyUseHTMLString: true
        })
      }
    },
    goRecharge() {
      this.$router.push('/recharge')
    }
  }
}
</script>

<style lang="less" scoped>
i.el-icon-warning-outline {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.routerBtn {
  padding: 0;
  min-height: auto;
}

a {
  text-decoration: none;
}

.accountNum {
  height: 180px;
}

.accountNum .el-row {
  margin-bottom: 8px;
}

.red {
  color: #f56c6c;
}</style>
