<template>
  <!-- TT新建广告账户对话框 -->
  <el-dialog v-model="dialogShow" title="新建广告账户" custom-class="createTT" @close="closeCreateTT" width="1000px">
    <el-steps :active="stepActive" finish-status="success" align-center>
      <el-step title="账号信息" />
      <el-step title="公司信息" />
    </el-steps>
    <!-- 内容主体区域 -->

    <div v-show="stepActive == 0">
      <el-alert title="帮助提示" description="" type="info" show-icon>
        <template #default>
          请提前准备好营业执照扫描件以便证明您的营业资质
          <!-- <el-link :underline="false" :href="`${base.url}/help/1132?type=1`" target="_blank" class="fillGuide">查看填写指南 -->
          <!-- </el-link> -->
          <br />请先输入营业执照名称，校验该执照是否可以开户
          <br>每次新建TikTok广告账户时，都会默认显示上一次您提交的开户信息方便您修改后再次提交
        </template>
      </el-alert>
      <el-form ref="createTTFormRef" :model="createTTForm" :rules="createTTFormRules" class="createTT-form"
        label-width="120px">
        <el-form-item label="营业执照名称" prop="licenseName">
          <el-input v-model="createTTForm.licenseName" placeholder="请输入营业执照上的公司名称" size="small" clearable
            :disabled="editDisable" @focus="inputFocus" @blur="inputBlur" />
          <div class="el-form-item__error" v-show="checkoutCode == '100'">
            {{ checkoutMsg }}
          </div>
        </el-form-item>
        <el-form-item label="账号币种" prop="currency">
          <el-select v-model="createTTForm.currency" placeholder="请选择币种" size="small" :disabled="editDisable">
            <el-option v-for="(item, index) in currencyData" :label="item.label" :value="item.value"
              :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业" prop="industry">
          <el-cascader v-model="createTTForm.industry" :options="industryData" :props="props" size="small"
            :disabled="editDisable" />
        </el-form-item>
        <el-form-item label="账号时区" prop="timezone">
          <el-select v-model="createTTForm.timezone" placeholder="请选择账号时区" size="small" :disabled="editDisable">
            <el-option v-for="(item, index) in timezoneData" :label="item.label" :value="item.value"
              :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="营业执照注册地" prop="registered_area">
          <el-select v-model="createTTForm.registered_area" placeholder="请选择营业执照注册地" size="small" :disabled="editDisable">
            <el-option v-for="(item, index) in registered_areaData" :label="item.label" :value="item.value"
              :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号类型" prop="type">
          <el-select v-model="createTTForm.type" placeholder="请选择账号类型" size="small" :disabled="editDisable">
            <el-option v-for="(item, index) in typeData" :label="item.label" :value="item.value" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号名称" prop="accountName[0].value">
          <el-row v-for="(item, index) in createTTForm.accountName" :key="index" :gutter="20">
            <el-col :span="20">
              <el-input v-model="item.value" placeholder="请输入账号名称" size="small" clearable :disabled="editDisable"
                @focus="accountFocus(item.value)" @blur="accountBlur(item.value)" />
              <div class="el-form-item__error" v-show="checkoutCode1[item.value] == '100'" style="left: auto;right: 0;">
                {{ checkoutMsg1[item.value] }}
              </div>
            </el-col>
            <el-col :span="4" style="display:flex;align-items: center;justify-content: right;">
              <el-button type="text" class="circleBtn" circle size="small" @click="addAccountName" :disabled="editDisable"
                v-if="index == 0">
                <i class="el-icon-circle-plus"></i>
              </el-button>
              <el-button type="text" class="circleBtn" circle size="small" @click="removeAccountName(item.id, item.value)"
                :disabled="editDisable" v-else>
                <i class="el-icon-remove"></i>
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="推广链接" prop="url" class="urlItem">
          <el-select v-model="createTTForm.url" multiple filterable allow-create :disabled="editDisable" size="small"
            default-first-option :reserve-keyword="false" placeholder="请输入推广URL，如有多个链接请输入后按回车选择" style="width: 100%;">
          </el-select>
          <div class="form-url-bottom">
            <p class="Text">
              注：每个账户都会关联全部的推广链接
            </p>
            <p><span>{{ urlLength }}</span>/255</p>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <div v-show="stepActive == 1">
      <el-alert class="tt-account-open-alert" title="TT上传执照注意事项" description="" type="info" show-icon :closable="false">
        a.请保证上传的执照照片是最新版（与国家公示系统一致）；且经营情况正常；<br />
        b.上传的执照是彩色照片<br />
        c.执照必须拍完整，包括外框及外框小字<br />
        d.不可以是电子执照<br />
        e.请务必保证图片高清、明亮，请尽量不要有阴影和色差<br />
        f.请不要带有水印<br />
        g.请在正上方对执照拍摄，避免照片倾斜<br />
        h.执照信息完整，包含经营范围。<br />
      </el-alert>
      <el-form ref="companyTTFormRef" :model="companyTTForm" :rules="companyTTFormRules" class="companyTT-form"
        label-width="120px">
        <el-form-item label="营业执照" prop="licensePath">
          <div style="position: relative;width: 100px;">
            <el-image style="width: 100px; height: 100px" :src="srcThumb" :preview-src-list="srcThumbList"
              :initial-index="4" fit="cover" v-show="imgThumb" />
            <i class="el-icon-error" v-show="imgThumb" @click="deleteLicensePath"></i>
          </div>
          <el-upload ref="files" :class="editDisable == true ? 'uploadDisable' : ''" :action="uploadURL"
            list-type="picture-card" :limit="1" accept=".jpeg,.png,.jpg" :disabled="editDisable"
            :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :before-upload="handleBefore"
            :on-success="handleSuccess" :headers="headerObj">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              <em>点击上传</em><br />
              支持jpeg、png、jpg格式
            </div>
          </el-upload>
          <el-dialog v-model="ImagedialogVisible">
            <img style="width: 100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>

        <el-form-item label="其他文件" prop="license">
          <div style="position: relative;display: inline-block;width: auto;margin-right: 10px;" v-show="imgThumb1">
            <template v-for="(src, index) in srcThumbList1" :key="index">
              <el-image style="width: 100px; height: 100px;margin-right: 10px;" :src="src"
                :preview-src-list="srcThumbList1" :initial-index="4" fit="cover" v-show="imgThumb1" />
            </template>
            <i class="el-icon-error" v-show="imgThumb1" @click="deleteOther"></i>
          </div>
          <el-upload ref="files" :class="editDisable == true ? 'uploadDisable' : ''" :action="uploadURL1"
            list-type="picture-card" :limit="3" :disabled="editUpload1" accept=".jpeg,.png,.jpg"
            :on-preview="handlePictureCardPreview1" :on-remove="handleRemove1" :on-success="handleSuccess1"
            :before-upload="beforeImgUpload" :headers="headerObj" @click="disabledClick">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              <em>点击上传</em><br />
              支持jpeg、png、jpg格式
            </div>
          </el-upload>
          <el-dialog v-model="ImagedialogVisible1">
            <img style="width: 100%" :src="dialogImageUrl1" alt="" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="营业执照编号" prop="licenseNum">
          <el-input v-model="companyTTForm.licenseNum" placeholder="请输入营业执照编号" size="small" disabled />
        </el-form-item>
        <el-form-item label="联系人" prop="manager">
          <el-input v-model="companyTTForm.manager" placeholder="请输入联系人姓名" size="small" clearable
            :disabled="editDisable" />
        </el-form-item>
        <el-form-item label="联系人邮箱" prop="email">
          <el-input v-model="companyTTForm.email" placeholder="请输入广告账户联系人邮箱" size="small" clearable
            :disabled="editDisable" />
        </el-form-item>
        <el-form-item label="联系人电话" prop="phone">
          <el-input v-model="companyTTForm.phone" placeholder="请输入广告账户联系人电话" size="small" clearable
            :disabled="editDisable" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer" v-if="stepActive == 0">
        <el-button size="small" type="primary" @click="createNextBtn">下一步</el-button>
      </span>
      <span class="dialog-footer" v-else>
        <el-button size="small" @click="createPreBtn">上一步</el-button>
        <el-button size="small" type="primary" @click="submitFormBtn" v-show="!editDisable">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { nanoid } from 'nanoid'
import { ElLoading } from 'element-plus'
import base from '../../../../request/base'
import api from '@/request/api/account'

export default {
  name: 'TTOpenAccount',
  props: ['type', 'selected'],
  // emits: ['handleDialog'],
  data() {
    const urlValidRule = (rule, value, cb) => {
      let reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/
      value.forEach(item => {
        if (!reg.test(item)) {
          cb(new Error('推广链接格式错误，需带http或者https'))
        }
      })
      return cb()
    }

    return {
      base: base,
      dialogShow: true,
      editDisable: false,
      editUpload1: false,
      editReload: false,
      imgThumb: false,
      imgThumb1: false,
      checkId: 0,
      stepActive: 0,
      // 账号信息
      currencyData: [],
      industryData: [],
      timezoneData: [],
      registered_area: [],
      registered_areaData: [],
      checkoutCode: '',
      checkoutMsg: '',
      checkoutCode1: [],
      checkoutCodeNext: [],
      checkoutMsg1: [],
      props: {
        expandTrigger: 'hover'
      },
      typeData: '',
      checkLicense: false,
      checkLicenseName: '',
      createTTForm: {
        licenseName: '',
        currency: '',
        industry: '',
        timezone: '',
        registered_area: '',
        type: '',
        // promotion_area: '',
        url: [],
        accountName: [
          {
            id: nanoid(),
            value: ''
          }
        ]
      },
      industryNew: '',
      accountNewName: [],
      createTTFormRules: {
        licenseName: [
          { required: true, message: '营业执照不能为空', trigger: 'blur' },
          {
            pattern: /^[\u4e00-\u9fa5\(\)\（\）]{0,}$/,
            message: '只能使用中文营业执照开户',
            trigger: 'change'
          }
        ],
        currency: [{ required: true, message: '请选择币种', trigger: 'change' }],
        industry: [{ required: true, message: '请选择行业', trigger: 'change' }],
        timezone: [{ required: true, message: '请选择账号时区', trigger: 'change' }],
        registered_area: [{ required: true, message: '请选择注册地', trigger: 'change' }],
        type: [{ required: true, message: '请选择账号类型', trigger: 'change' }],
        'accountName[0].value': [{ required: true, message: '请输入账号名称', trigger: 'blur' }],
        url: [
          { required: true, message: '请输入带http或者https推广URL', trigger: 'change' },
          { validator: urlValidRule, trigger: 'change' }
        ]
      },
      // 公司信息
      license_code: '',
      license_msg: '',
      country: '',
      companyTTForm: {
        licensePath: '',
        licenseIds: [],
        licenseIds1: [],
        licenseNum: '',
        manager: '',
        email: '',
        phone: '',
        name: []
      },
      companyTTFormRules: {
        licensePath: [{ required: true, message: '请上传营业执照', trigger: 'change' }],
        licenseNum: [{ required: true, message: '请输入营业执照编号', trigger: 'change' }]
      },
      // 营业执照图片上传的地址
      srcThumb: '',
      srcThumbList: [],
      uploadDisabled: false,
      uploadURL: `${base.admin_url}/cloud/file/uploadfile_tt`,
      ImagedialogVisible: false,
      dialogImageUrl: '',
      // 其他文件上传
      srcThumb1: '',
      srcThumbList1: [],
      editImageIds: [],
      uploadURL1: `${base.admin_url}/cloud/file/uploadfile_tt_qt`,
      ImagedialogVisible1: false,
      dialogImageUrl1: '',
      // 图片上传时的headers请求头
      headerObj: {
        Authorization: this.$store.getters.userInfo.token
      },
      req_id: 0
    }
  },
  computed: {
    urlLength() {
      return this.createTTForm.url.reduce((total, item) => {
        return total + item.length
      }, 0)
    }
  },
  mounted() {
    this.getCreatFormData()
    if (this.type === 'edit') this.getEditCreatFormData(this.selected)
    if (this.type === 'show') this.postLookStatus(this.selected)
    // this.$bus.on('openCreateTTDialog', this.openCreateTTDialog)
    // this.$bus.on('getEditFormData', this.getEditCreatFormData, 'id')
    // this.$bus.on('postLookStatus', this.postLookStatus, 'id', 'status', 'status1')
  },
  methods: {
    openCreateTTDialog() {
      this.dialogShow = true
    },
    closeCreateTT() {
      this.imgThumb = false
      this.imgThumb1 = false
      this.editUpload1 = false
      this.stepActive = 0
      this.$emit('close')
    },
    // 开户历史页编辑按钮时开户信息赋值
    async getEditCreatFormData(param) {
      this.license_code = 200
      this.checkId = param.id
      if (param.status != 1) {
        this.editDisable = false
        this.editReload = true
      }
      const { data: res } = await this.$http.post('Cloudtt/open_account', {
        id: param.id,
        type: 1
      })
      this.editImageIds = res.data.qualification_image_ids
      if (res.data.file_name_path != '') {
        this.imgThumb = true
      }
      if (res.data.qualification_image_ids != '') {
        this.imgThumb1 = true
        this.editUpload1 = true
      }
      this.checkLicenseName = res.data.legal_name
      this.createTTForm.licenseName = res.data.legal_name
      this.createTTForm.currency = res.data.currency
      this.createTTForm.industry = res.data.industry
      this.createTTForm.timezone = res.data.timezone
      this.createTTForm.registered_area = res.data.registered_area
      this.createTTForm.type = res.data.type
      this.createTTForm.accountName = res.data.name
      this.createTTForm.url = res.data.promotion_link
      this.country = res.data.is_tt_white
      this.companyTTForm.licensePath = res.data.file_name
      this.companyTTForm.licenseIds = res.data.qualification_image_ids
      this.companyTTForm.licenseIds1 = res.data.qualification_image_ids_other
      this.companyTTForm.licenseNum = res.data.license_no
      this.companyTTForm.manager = res.data.contact_name
      this.companyTTForm.email = res.data.email
      this.companyTTForm.phone = res.data.number
      this.companyTTForm.name = res.data.qualification_oss_name
      this.req_id = res.data.req_id
      this.srcThumb = res.data.file_name_path
      this.srcThumbList.push(this.srcThumb)
      this.srcThumbList1 = res.data.qualification_oss_path
    },
    // 开户历史页查看按钮时开户信息赋值及禁用
    async postLookStatus(param) {
      if (param.status == 1 || param.status1 == '初审中') {
        this.editDisable = true
        this.editUpload1 = true
        this.imgThumb = true
      }
      const { data: res } = await this.$http.post('Cloudtt/open_account', {
        id: param.id,
        type: 1
      })
      if (res.data.file_name_path != '') {
        this.imgThumb = true
      }
      if (res.data.qualification_image_ids != '') {
        this.imgThumb1 = true
      }
      this.createTTForm.licenseName = res.data.legal_name
      this.createTTForm.currency = res.data.currency
      this.createTTForm.industry = res.data.industry
      this.createTTForm.timezone = res.data.timezone
      this.createTTForm.registered_area = res.data.registered_area
      this.createTTForm.type = res.data.type
      this.createTTForm.accountName = res.data.name
      this.createTTForm.url = res.data.promotion_link
      this.country = res.data.is_tt_white
      this.companyTTForm.licensePath = res.data.file_name
      this.companyTTForm.licenseIds = res.data.qualification_image_ids
      this.companyTTForm.licenseIds1 = res.data.qualification_image_ids_other
      this.companyTTForm.licenseNum = res.data.license_no
      this.companyTTForm.manager = res.data.contact_name
      this.companyTTForm.email = res.data.email
      this.companyTTForm.phone = res.data.number
      this.companyTTForm.name = res.data.qualification_oss_name
      this.srcThumb = res.data.file_name_path
      this.srcThumbList.push(this.srcThumb)
    },
    async getCreatFormData() {
      let res = await api.tt.getAccountData()
      this.currencyData = res.data.currency
      this.industryData = res.data.industry
      this.timezoneData = res.data.timezone
      this.registered_areaData = res.data.registered_area
      this.typeData = res.data.type

      if (!res.form) return null

      this.checkLicenseName = res.form.legal_name
      this.createTTForm.licenseName = res.form.legal_name
      this.createTTForm.currency = res.form.currency
      this.createTTForm.industry = res.form.industry
      this.createTTForm.timezone = res.form.timezone
      this.createTTForm.registered_area = res.form.registered_area
      this.createTTForm.type = res.form.type
      this.createTTForm.url = res.form.promotion_link

      this.companyTTForm.licensePath = res.form.file_name
      this.companyTTForm.licenseIds = res.form.qualification_image_ids
      this.companyTTForm.licenseIds1 = res.form.qualification_image_ids_other
      this.companyTTForm.licenseNum = res.form.license_no
      this.companyTTForm.manager = res.form.contact_name
      this.companyTTForm.email = res.form.email
      this.companyTTForm.phone = res.form.number
      this.companyTTForm.name = res.form.qualification_oss_name

      if (res.form.show_file_name) {
        this.imgThumb = true
        this.srcThumb = res.form.show_file_name
        this.srcThumbList.push(this.srcThumb)
      }

    },
    inputFocus() {
      this.checkoutCode = ''
    },
    async inputBlur() {
      if (this.createTTForm.licenseName != '') {
        const { data: res } = await this.$http.post('Cloudtt/account_check', {
          entity_name: this.createTTForm.licenseName
        })
        this.checkoutCode = res.code
        this.checkoutMsg = res.msg
      }
    },
    accountFocus(value) {
      this.checkoutCode1[value] = ''
    },
    async accountBlur(value) {
      if (value != '') {
        const { data: res } = await this.$http.post('Cloudtt/select_name', {
          name: value,
          id: this.checkId
        })

        this.checkoutCode1[value] = res.code
        this.checkoutMsg1[value] = res.msg
      }
    },
    addAccountName() {
      const valueObj = { id: nanoid(), value: '' }
      if (this.createTTForm.accountName.length >= 5) return this.$message.warning('最多输入5个账号名称')
      this.createTTForm.accountName.push(valueObj)
    },
    removeAccountName(id, value) {
      this.createTTForm.accountName = this.createTTForm.accountName.filter(item => item.id !== id)
      this.checkoutCode1[value] = ''
    },
    createPreBtn() {
      this.stepActive = 0
    },
    createNextBtn() {
      this.$refs.createTTFormRef.validate(async valid => {
        if (!valid) return
        if (this.checkoutCode == 100) return
        for (var i in this.checkoutCode1) {
          if (this.checkoutCode1[i] == 100) return
        }
        this.stepActive = 1
      })
    },
    async submitFormBtn() {
      this.$refs.companyTTFormRef.validate(async valid => {
        if (!valid) return
        if (this.license_code == 100) {
          return this.$message.error(this.license_msg)
        }
        if (this.checkLicense == false) {
          if (this.createTTForm.licenseName != this.checkLicenseName) {
            return this.$message.error('您填写的营业执照名称与上传的营业执照不符合')
          }
        }
        // 5个账户名称需提交的数据W
        this.createTTForm.accountName.forEach(item => {
          this.accountNewName.push(item.value)
        })
        // 行业数组第二个值
        this.industryNew = this.createTTForm.industry[1]

        // height: this.height,（浏览器信息）
        // width: this.width,
        // browser: this.browser,
        // os: this.os

        const loading = ElLoading.service({
          spinner: 'el-icon-loading',
          lock: true,
          text: '正在提交，请稍后...'
        })
        const { data: res } = await this.$http
          .post('Cloudtt/open_account', {
            legal_name: this.createTTForm.licenseName,
            currency: this.createTTForm.currency,
            industry: this.industryNew,
            timezone: this.createTTForm.timezone,
            registered_area: this.createTTForm.registered_area,
            type: this.createTTForm.type,
            name: this.accountNewName,
            promotion_link: this.createTTForm.url,
            is_tt_white: this.country,
            file_name: this.companyTTForm.licensePath,
            qualification_image_ids: this.companyTTForm.licenseIds,
            qualification_image_ids_other: this.companyTTForm.licenseIds1,
            license_no: this.companyTTForm.licenseNum,
            contact_name: this.companyTTForm.manager,
            email: this.companyTTForm.email,
            number: this.companyTTForm.phone,
            qualification_oss_name: this.companyTTForm.name,
            req_id: this.req_id
          })
          .finally(() => {
            loading.close()
          })
        this.accountNewName = []
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }
        if (res.code == 300) {
          this.$router.push('/login')
        }
        this.dialogShow = false
        this.$message.success('提交成功')
        if (this.editReload == true) {
          this.$bus.emit('getHistoryList')
        } else {
          this.$router.push({
            name: 'account_history',
            query: {
              type: 'tiktok'
            }
          })
        }
      })
    },
    // 处理图片预览功能
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.response.path
      this.ImagedialogVisible = true
    },
    // 移除图片的按钮
    handleRemove(file) {
      if (this.editReload == false) {
        this.$http.post('file/delete_img', {
          name: this.companyTTForm.licensePath
        })
      }
      this.companyTTForm.licensePath = ''
    },
    handleBefore() {
      this.checkLicense = true
    },
    // 图片上传成功的回调
    handleSuccess(response) {
      this.imgThumb = false
      this.license_code = response.code
      if (this.license_code == 100) {
        this.license_msg = response.msg
        return this.$message.error(response.msg)
      }
      this.companyTTForm.licensePath = response.path1
      this.companyTTForm.licenseNum = response.name
      if (this.createTTForm.licenseName != response.license_name) {
        this.license_msg = '上传的营业执照与您填写的营业执照名称不对应'
        this.$message.error('上传的营业执照与您填写的营业执照名称不对应')
        this.license_code = 100
      }
    },
    // 其他文件上传
    handlePictureCardPreview1(file) {
      this.dialogImageUrl1 = file.response.path
      this.ImagedialogVisible1 = true
    },
    // 移除图片的按钮
    handleRemove1(file) {
      var img = file.name.substring(file.name.lastIndexOf('.') + 1)
      const suffix = img === 'jpg'
      const suffix2 = img === 'png'
      const suffix3 = img === 'jpeg'
      const suffix4 = img == 'JPG'
      const suffix5 = img == 'PNG'
      const suffix6 = img == 'JPEG'
      if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6) {
        return false
      }
      let index = this.companyTTForm.licenseIds.findIndex(item => item == file.response.image_id)
      this.companyTTForm.licenseIds.splice(index, 1)
      let index2 = this.companyTTForm.licenseIds1.findIndex(item => item == file.response.image_id1)
      this.companyTTForm.licenseIds1.splice(index2, 1)
      let index1 = this.companyTTForm.name.findIndex(item => item == file.response.path1)
      this.companyTTForm.name.splice(index1, 1)
      if (this.editReload == false) {
        this.$http.post('file/delete_img', {
          name: file.response.path1
        })
      }
    },
    // 编辑其他文件有文件时
    disabledClick() {
      if (this.editUpload1 == true) {
        return this.$message.warning('重新上传需先清空已上传的其他资质文件')
      }
    },
    beforeImgUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf('.') + 1)
      const suffix = img === 'jpg'
      const suffix2 = img === 'png'
      const suffix3 = img === 'jpeg'
      // const suffix4 = img == 'JPG'
      // const suffix5 = img == 'PNG'
      // const suffix6 = img == 'JPEG'
      // && !suffix4 && !suffix5 && !suffix6
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error('仅支持上传图片！')
        return false
      }
    },
    // 图片上传成功的回调
    handleSuccess1(response) {
      if (response.code == 100) {
        this.$message.error(response.msg)
      }
      this.companyTTForm.licenseIds.push(response.image_id)
      this.companyTTForm.licenseIds1.push(response.image_id1)
      this.companyTTForm.name.push(response.path1)
    },
    // 删除营业执照
    deleteLicensePath() {
      this.companyTTForm.licensePath = ''
      this.imgThumb = false
    },
    async deleteOther() {
      const confirmResult = await this.$confirm(`即将清空已上传的其他资质文件`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      if (confirmResult !== 'confirm') {
        return
      } else {
        this.companyTTForm.licenseIds = []
        this.companyTTForm.name = []
        this.imgThumb1 = false
        this.editUpload1 = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.createTT {

  .el-steps,
  .el-alert--info.is-light {
    margin-bottom: 20px;
  }

  .el-steps {

    :deep(.el-step__head.is-finish),
    :deep(.el-step__title.is-finish) {
      color: #2b82ff !important;
    }
  }

  .asterisk {
    color: #f56c6c;
  }
}

.createTT-form,
.companyTT-form {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 50%;
  }

  .el-select {
    width: 100%;
  }
}

.createTT-form {
  .urlItem :deep(.el-input__suffix) {
    display: none;
  }

  :deep(.el-form-item:last-child),
  :deep(.el-form-item:nth-child(7)) {
    .el-form-item__error {
      position: relative;
      top: 0;
    }
  }
}

.form-url-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: #2b82ff;
  }
}

:deep(.el-upload),
:deep(.el-upload-dragger) {
  width: 100% !important;

  .el-upload__text {
    position: relative;
    bottom: 60px;
    line-height: 1.5;
  }

  em {
    font-style: normal;
  }
}

.el-upload--picture-card i {
  font-size: 55px;
  color: #2b82ff;
}

:deep(.el-upload--picture-card) {
  border: 1px dashed #2b82ff;
}

.createTT-form {
  :deep(.el-cascader) {
    width: 100%;
  }
}

.circleBtn {
  padding: 0;

  .el-icon-circle-plus,
  .el-icon-remove {
    font-size: 30px;
    color: #2b82ff;
  }
}

.uploadDisable {
  :deep(.el-upload--picture-card:hover) {
    color: #606266 !important;
  }

  :deep(.el-upload:focus) {
    color: #606266 !important;
  }

  .el-upload--picture-card i {
    color: #e4e7ed !important;
  }

  :deep(.el-upload--picture-card) {
    border: 1px dashed #e4e7ed !important;
  }
}

.el-icon-error {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 26px;
  cursor: pointer;
}

.fillGuide {
  color: #2b82ff;
  vertical-align: baseline !important;
  margin-left: 5px;
}

.tt-account-open-alert {
  line-height: 1.5;
}
</style>
