<template>
  <el-dialog v-model="isDialogShow" title="多账户操作" custom-class="blockedHandle" :close-on-click-modal="false"
    @close="$emit('close')" width="760px">
    <el-form label-position="left" :rules="rules" ref="ruleFormRef" :model="ruleForm">
      <el-form-item label="操作类型：" style="margin-bottom: 0">
        <el-radio-group v-model="handleType">
          <el-radio label="账户充值">账户充值</el-radio>
          <el-radio label="余额清零">余额清零</el-radio>
          <el-radio label="余额转移">余额转移</el-radio>
          <el-radio label="BM绑定">BM绑定</el-radio>
          <el-radio label="BM解绑">BM解绑</el-radio>
          <el-radio label="余额减款" v-if="props.creditType === 2">余额减款</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-alert type="info" show-icon :closable="false" v-show="handleType == '账户充值'">
        <template #default> 被封账户无法充值 </template>
      </el-alert>
      <el-alert type="info" show-icon :closable="false" v-show="handleType == '余额清零'">
        <template #default>
          广告系列完全暂停6小时后才能够进行余额清零操作。
        </template>
      </el-alert>
      <el-alert type="info" show-icon :closable="false" v-show="handleType == '余额减款'">
        <template #default>
          将您广告账户中的余额回收到钱包中
        </template>
      </el-alert>
      <el-alert type="info" show-icon :closable="false" v-show="handleType == '余额转移'">
        <template #default>
          被封账户仅能余额转移一次，且需要将余额全部转移
        </template>
      </el-alert>

      <div v-if="handleType == '账户充值'">
        <el-row v-for="(item, index) in ruleForm.accountRecharge" type="flex" justify="space-between">
          <el-form-item label="账户ID" style="margin-right: 10px" :prop="`accountRecharge[${index}].id`"
            :rules="{ required: true, message: '请输入账户ID', trigger: 'blur' }">
            <el-input v-model="item.id" placeholder='多个账户ID请用英文逗号","隔开' size="small" clearable style="width: 300px" />
          </el-form-item>
          <el-form-item label="充值金额" :prop="`accountRecharge[${index}].amount`"
            :rules="{ required: true, message: '请输入充值金额', trigger: 'blur' }">
            <el-input v-model="item.amount" size="small" clearable style="width: 150px" />
          </el-form-item>
          <el-button v-if="index === 0" type="primary" circle @click="addAccountRecharge(index)" size="small"
            :disabled="ruleForm.accountRecharge.length === 5">
            <el-icon>
              <plus />
            </el-icon>
          </el-button>
          <el-button v-else type="primary" circle @click="deleteAccountRecharge(index)">
            <el-icon>
              <close />
            </el-icon>
          </el-button>
        </el-row>
      </div>

      <div v-else-if="handleType == '余额转移'">
        <el-row>
          <el-form-item label="转移类型" class="transfer-form-item">
            <el-select v-model="moveType" size="small">
              <el-option label="单转多" :value="1"></el-option>
              <el-option label="多转单" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <div v-if="moveType === 1">
          <el-row type="flex" justify="space-between">
            <el-form-item label="转出账户ID" style="margin-right: 10px" prop="balanceTransferSToM.id">
              <el-input v-model="ruleForm.balanceTransferSToM.id" placeholder="只能输入一个账户ID" size="small" clearable
                style="width: 200px" @change="getAccountBalance" />
            </el-form-item>
            <el-form-item label="账户余额" style="margin-left: 8px;">
              <el-input v-model="ruleForm.balanceTransferSToM.balanceAmount" size="small" disabled
                style="width: 110px;" />
            </el-form-item>
            <el-form-item label="转出金额">
              <el-input v-model="ruleForm.balanceTransferSToM.transferAmount" size="small" disabled
                style="width: 110px" />
            </el-form-item>
          </el-row>
          <el-row v-for="(item, index) in ruleForm.balanceTransferSToM.children">
            <el-form-item label="转入账户ID" style="margin-right: 34px" :prop="`balanceTransferSToM.children[${index}].id`"
              :rules="{ required: true, message: '请输入账户ID', trigger: 'blur' }">
              <el-input v-model="item.id" placeholder='多个账户ID请用英文逗号","隔开' size="small" clearable style="width: 200px" />
            </el-form-item>
            <el-form-item label="充值金额" style="margin-right: 20px" :prop="`balanceTransferSToM.children[${index}].amount`"
              :rules="{ required: true, message: '请输入充值金额', trigger: 'blur' }">
              <el-input v-model="item.amount" size="small" clearable style="width: 110px" />
            </el-form-item>
            <el-button v-if="index === 0" type="primary" circle @click="addBalanceTransferSToM(index)" size="small"
              :disabled="ruleForm.balanceTransferSToM.children.length === 5">
              <el-icon>
                <plus />
              </el-icon>
            </el-button>
            <el-button v-else type="primary" circle @click="deleteBalanceTransferSToM(index)">
              <el-icon>
                <close />
              </el-icon>
            </el-button>
          </el-row>
        </div>
        <div v-if="moveType === 2">
          <el-row v-for="(item, index) in ruleForm.balanceTransferMToS.children" type="flex" justify="space-between">
            <el-form-item label="转出账户ID" style="margin-right: 10px" :prop="`balanceTransferMToS.children[${index}].id`"
              :rules="{ required: true, message: '请输入账户ID', trigger: 'blur' }">
              <el-input v-model="item.id" placeholder="只能输入一个账户ID" size="small" clearable style="width: 200px"
                @change="getAccountBalanceMToS(index)" />
            </el-form-item>
            <el-form-item label="账户余额">
              <el-input v-model="item.balanceAmount" size="small" disabled style="width: 110px" />
            </el-form-item>
            <el-form-item label="转出金额" :prop="`balanceTransferMToS.children[${index}].transferAmount`"
              :rules="{ required: true, message: '请输入转出金额', trigger: 'blur' }">
              <el-input v-model="item.transferAmount" size="small" clearable style="width: 110px" />
            </el-form-item>
            <el-button v-if="index === 0" type="primary" circle @click="addBalanceTransferMToS(index)" size="small"
              :disabled="ruleForm.balanceTransferMToS.children.length === 5">
              <el-icon>
                <plus />
              </el-icon>
            </el-button>
            <el-button v-else type="primary" circle @click="deleteBalanceTransferMToS(index)">
              <el-icon>
                <close />
              </el-icon>
            </el-button>
          </el-row>
          <el-row>
            <el-form-item label="转入账户ID" style="margin-right: 16px" prop="balanceTransferMToS.id">
              <el-input v-model="ruleForm.balanceTransferMToS.id" size="small" clearable style="width: 200px" />
            </el-form-item>
            <el-form-item label="充值总额">
              <el-input v-model="ruleForm.balanceTransferMToS.rechargeAmount" size="small" disabled
                style="width: 110px" />
            </el-form-item>
          </el-row>
        </div>
      </div>

      <div v-else-if="handleType == '余额清零'">
        <el-row>
          <el-form-item label="账户ID" prop="zeroBalance">
            <el-input v-model="ruleForm.zeroBalance" placeholder='请输入需要余额清零的账户ID。多个账户ID请用英文逗号","隔开' size="small" clearable
              style="width: 450px" />
          </el-form-item>
        </el-row>
      </div>

      <div v-else-if="handleType == 'BM绑定'">
        <el-row>
          <el-form-item label="账户ID" prop="bmBinding.id">
            <el-input v-model="ruleForm.bmBinding.id" placeholder='多个账户ID请用英文逗号","隔开' size="small" clearable
              style="width: 300px" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="BMID" prop="bmBinding.BMID">
            <el-input v-model="ruleForm.bmBinding.BMID" placeholder="只能输入一个BMID" size="small" clearable
              style="width: 300px; margin-left: 6px" />
          </el-form-item>
        </el-row>
      </div>

      <div v-else-if="handleType == 'BM解绑'">
        <el-row>
          <el-form-item label="账户ID" prop="bmUnbinding.id">
            <el-input v-model="ruleForm.bmUnbinding.id" placeholder='多个账户ID请用英文逗号","隔开' size="small" clearable
              style="width: 300px" />
          </el-form-item>
          <el-row>
            <el-form-item label="BMID" prop="bmUnbinding.BMID">
              <el-input v-model="ruleForm.bmUnbinding.BMID" placeholder="只能输入一个BMID" size="small" clearable
                style="width: 300px; margin-left: 6px" />
            </el-form-item>
          </el-row>
        </el-row>
      </div>

      <div v-else>
        <el-row v-for="(item, index) in ruleForm.deduction" type="flex" justify="space-between">
          <el-form-item label="账户ID" style="margin-right: 10px" :prop="`deduction[${index}].id`"
            :rules="{ required: true, message: '请输入账户ID', trigger: 'blur' }">
            <el-input v-model="item.id" placeholder='请输入需要进行减款的账户ID。多个账户ID请用英文逗号","隔开' size="small" clearable
              style="width: 410px" />
          </el-form-item>
          <el-form-item label="减款金额" :prop="`deduction[${index}].amount`"
            :rules="{ required: true, message: '请输入充值金额', trigger: 'blur' }">
            <el-input v-model="item.amount" size="small" clearable style="width: 110px" />
          </el-form-item>
          <el-button v-if="index === 0" type="primary" circle @click="addDeduction(index)" size="small"
            :disabled="ruleForm.deduction.length === 5">
            <el-icon>
              <plus />
            </el-icon>
          </el-button>
          <el-button v-else type="primary" circle @click="deleteDeduction(index)">
            <el-icon>
              <close />
            </el-icon>
          </el-button>
        </el-row>
      </div>

    </el-form>
    <template #footer>
      <el-button size="small" @click="$emit('close')">取消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm(ruleFormRef)">确定</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import api from '@/request/api/account'
import { ref, reactive, computed, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus'

const { proxy } = getCurrentInstance()

const props = defineProps({
  // 客户类型，1：预付，2：后付
  creditType: {
    type: Number,
  }
})

// 余额转移单转多转出金额计算
const transferAmountComputed = computed(() => {
  return ruleForm.value.balanceTransferSToM.children.reduce((total, item) => {
    return isNaN(parseFloat(item.amount)) ? total : total + parseFloat(item.amount)
  }, 0)
})
// 余额转移多转单充值总额计算
const rechargeAmountComputed = computed(() => {
  return ruleForm.value.balanceTransferMToS.children.reduce((total, item) => {
    return isNaN(parseFloat(item.transferAmount)) ? total : total + parseFloat(item.transferAmount)
  }, 0)
})

const isDialogShow = ref(true) // 是否显示弹窗
const handleType = ref('账户充值') // 多账户操作类型
const moveType = ref(1) // 余额转移类型 1: 单转多 2: 多转单
const ruleForm = ref({
  // 账户充值
  accountRecharge: [{ id: '', amount: '' }],
  // 余额转移单转多
  balanceTransferSToM: {
    id: '',
    balanceAmount: '',
    transferAmount: transferAmountComputed,
    children: [{ id: '', amount: '' }],
  },
  // 余额转移多转单
  balanceTransferMToS: {
    id: '',
    rechargeAmount: rechargeAmountComputed,
    children: [{ id: '', balanceAmount: '', transferAmount: '' }],
  },
  // 余额清零
  zeroBalance: '',
  // BM绑定
  bmBinding: {
    id: '',
    BMID: '',
  },
  // BM解绑
  bmUnbinding: {
    id: '',
    BMID: '',
  },
  // 余额减款
  deduction: [{
    id: '',
    amount: '',
  }]
})
const ruleFormRef = ref()
// 表单验证规则
const rules = reactive({
  zeroBalance: [{ required: true, message: '请输入账户ID', trigger: 'blur' }],
  'balanceTransferSToM.id': [{ required: true, message: '请输入账户ID', trigger: 'blur' }],
  'balanceTransferMToS.id': [{ required: true, message: '请输入账户ID', trigger: 'blur' }],
  'bmBinding.id': [{ required: true, message: '请输入账户ID', trigger: 'blur' }],
  'bmBinding.BMID': [{ required: true, message: '请输入BMID', trigger: 'blur' }],
  'bmUnbinding.id': [{ required: true, message: '请输入账户ID', trigger: 'blur' }],
  'bmUnbinding.BMID': [{ required: true, message: '请输入BMID', trigger: 'blur' }],
})

// 确认操作
const handleConfirm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (!valid) return
    switch (handleType.value) {
      case '账户充值':
        handleAccountRecharge()
        break

      case '余额转移':
        handleBalanceTransfer()
        break

      case '余额清零':
        handleZeroBalance()
        break

      case 'BM绑定':
        handleBmBinding()
        break

      case 'BM解绑':
        handleBmUnbinding()
        break

      case '余额减款':
        handleDeduction()
        break

      default:
        break
    }
  })
}
// 添加账户充值列表
const addAccountRecharge = (index) => {
  ruleForm.value.accountRecharge.push({ id: '', amount: '' })
}
// 删除账户充值列表
const deleteAccountRecharge = (index) => {
  ruleForm.value.accountRecharge.splice(index, 1)
}
// 添加余额转移单转多列表
const addBalanceTransferSToM = (index) => {
  ruleForm.value.balanceTransferSToM.children.push({ id: '', amount: '' })
}
// 删除余额转移单转多列表
const deleteBalanceTransferSToM = (index) => {
  ruleForm.value.balanceTransferSToM.children.splice(index, 1)
}
// 添加余额转移多转单列表
const addBalanceTransferMToS = (index) => {
  ruleForm.value.balanceTransferMToS.children.push({ id: '', balanceAmount: '', transferAmount: '' })
}
// 删除余额转移多转单列表
const deleteBalanceTransferMToS = (index) => {
  ruleForm.value.balanceTransferMToS.children.splice(index, 1)
}
// 添加余额减款列表
const addDeduction = (index) => {
  ruleForm.value.deduction.push({ id: '', amount: '' })
}
// 删除余额减款列表
const deleteDeduction = (index) => {
  ruleForm.value.deduction.splice(index, 1)
}
// 单转多查询账户余额
const getAccountBalance = async () => {
  let res = await api.facebook.getAccountBalance({
    account_id: parseInt(ruleForm.value.balanceTransferSToM.id),
    type: 2,
  })
  ruleForm.value.balanceTransferSToM.balanceAmount = res.account_balance
}
// 多转单查询账户余额
const getAccountBalanceMToS = async (index) => {
  let res = await api.facebook.getAccountBalance({
    account_id: parseInt(ruleForm.value.balanceTransferMToS.children[index].id),
    type: 2,
  })
  ruleForm.value.balanceTransferMToS.children[index].balanceAmount = res.account_balance
}
// 账户充值请求
const requestAccountRecharge = async () => {
  let res = await api.facebook.accountOperate({
    recharge_account_ids: ruleForm.value.accountRecharge.map((item) => {
      return {
        account_id: item.id,
        money: parseFloat(item.amount),
      }
    }),
    type: 3,
  })
  ElMessage.success(res.msg)
  ruleFormRef.value.resetFields()
  isDialogShow.value = false
}
// 账户充值
const handleAccountRecharge = async () => {
  const rechargeHistory = await api.common.getRechargeHistory({
    type: 2,
    media_type: 1,
    recharge_accounts: JSON.stringify(ruleForm.value.accountRecharge.map((item) => {
      return {
        account_id: item.id,
        money: parseFloat(item.amount),
      }
    }))
  })
  if (rechargeHistory.exa === 1) {
    // 24小时有同金额充值记录
    const confirmRecharge = await proxy.$confirm(
      `${rechargeHistory.account_id}账户24小时内有同金额充值记录,确定要继续充值吗?`,
      '提示',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).catch((err) => err)
    if (confirmRecharge !== 'confirm') return
    return requestAccountRecharge()
  }
  requestAccountRecharge()
}
// 余额转移
const handleBalanceTransfer = async () => {
  let params =
    moveType.value === 1
      ? {
        transfer_out_account: [{ account_id: ruleForm.value.balanceTransferSToM.id, amount: transferAmountComputed.value }],
        transfer_in_account: ruleForm.value.balanceTransferSToM.children.map((item) => {
          return {
            account_id: item.id,
            amount: parseFloat(item.amount),
          }
        }),
        type: 1,
        media_type: 1,
      }
      : {
        transfer_out_account: ruleForm.value.balanceTransferMToS.children.map((item) => {
          return {
            account_id: item.id,
            amount: parseFloat(item.transferAmount),
          }
        }),
        transfer_in_account: [{ account_id: ruleForm.value.balanceTransferMToS.id, amount: rechargeAmountComputed.value }],
        type: 2,
        media_type: 1,
      }
  let res = await api.facebook.handleAccountTransfer(params)
  ElMessage.success(res.msg)
  isDialogShow.value = false
  ruleFormRef.value.resetFields()
}
// 余额清零
const handleZeroBalance = async () => {
  // 确认是否有未暂停的广告系列
  let pauseAccount = await api.facebook.getPauseAccount({ acc_ids: JSON.stringify(ruleForm.value.zeroBalance.split(',')) })
  if (pauseAccount.pause === 0) {
    let res = await api.facebook.accountOperate({
      account_ids: ruleForm.value.zeroBalance,
      type: 4,
    })
    ElMessage.success(res.msg)
    isDialogShow.value = false
    ruleFormRef.value.resetFields()
  } else {
    const confirmResult = await proxy.$confirm(
      `账户ID：${pauseAccount.account_id}的广告系列未暂停，点击确定将立即暂停广告系列。广告系列暂停6小时后，账户将自动清零。若等待清零时对账户进行操作需继续等待6小时，确保广告系列完全暂停6小时后进行清零。`,
      '提示',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).catch((err) => err)
    if (confirmResult !== 'confirm') return
    let res = await api.facebook.accountOperate({
      account_ids: ruleForm.value.zeroBalance,
      type: 4,
    })
    ElMessage.success(res.msg)
    isDialogShow.value = false
    ruleFormRef.value.resetFields()
  }
}
// BM绑定
const handleBmBinding = async () => {
  let res = await api.facebook.accountOperate({
    account_ids: ruleForm.value.bmBinding.id,
    bm_id: ruleForm.value.bmBinding.BMID,
    type: 1,
  })
  ElMessage.success(res.msg)
  isDialogShow.value = false
  ruleFormRef.value.resetFields()
}
// BM解绑
const handleBmUnbinding = async () => {
  let res = await api.facebook.accountOperate({
    account_ids: ruleForm.value.bmUnbinding.id,
    bm_id: ruleForm.value.bmUnbinding.BMID,
    type: 2,
  })
  ElMessage.success(res.msg)
  isDialogShow.value = false
  ruleFormRef.value.resetFields()
}
// 余额减款
const handleDeduction = async () => {
  let res = await api.facebook.accountOperate({
    recharge_account_ids: ruleForm.value.deduction.map((item) => {
      return {
        account_id: item.id,
        money: parseFloat(item.amount),
      }
    }),
    type: 5,
  })
  ElMessage.success(res.msg)
  ruleFormRef.value.resetFields()
  isDialogShow.value = false
}
</script>

<style lang="less" scoped>
.blockedHandle {
  .el-select {
    width: 100%;
  }
}

.el-form .el-button {
  height: 32px;
  min-height: 32px;
  width: 32px;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 20px;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}

:deep(.transfer-form-item .el-form-item__content) {
  width: 200px;
}

:deep(.transfer-form-item .el-form-item__label) {
  margin-left: 24px;
}
</style>
