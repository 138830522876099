<template>
  <el-card class="box-card balance-card">
    <template #header>
      <el-row justify="space-between" class="card-header">
        <span class="SubHead">
          可用余额
          <el-tooltip class="item" effect="light" content="" placement="top">
            <template #content>
              可用余额：扣除掉账户余额后，还可以用于账户充值的金额；<br />
              账户余额：当前账户状态为正常的账户，账户余额的总和。
            </template>
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </span>
      </el-row>
    </template>
    <el-row v-loading="loading">
      <el-col :span="24" class="Text" :class="balanceInfo.num_type == 1 ? 'color-danger' : ''">
        <el-tooltip
          v-if="$store.getters.userInfo.exc !== 'qbit' && $store.getters.userInfo.exc !== '' && $store.getters.userInfo.exc !== null"
          trigger="hover" placement="top" effect="light" width="200">
          <el-button style="padding-bottom: 10px;" type="text">可用余额：${{ balanceInfo.total_balance }}</el-button>
          <template #content>
            <el-space direction="vertical" :size="10" fill style="width: 100%">
              <span>钱包余额：${{ filters.thousands(balanceInfo.balance) }}</span>
              <span :span="24">礼金余额：${{ filters.thousands(balanceInfo.bonus_balance) }}</span>
              <span :span="24">注：赠予礼金仅能用于Facebook账户充值</span>
            </el-space>
          </template>
        </el-tooltip>
        <el-tooltip v-if="$store.getters.userInfo.exc === 'qbit'" trigger="hover" placement="top" effect="light"
          width="200">
          <el-button style="padding-bottom: 10px;" type="text">可用余额：${{ balanceInfo.total_balance }}</el-button>
          <template #content>
            <el-space direction="vertical" :size="10" fill style="width: 100%">
              <span>钱包余额：${{ filters.thousands(balanceInfo.balance) }}</span>
            </el-space>
          </template>
        </el-tooltip>
        <span v-if="$store.getters.userInfo.exc === '' || $store.getters.userInfo.exc === null"
          style="display: inline-block;margin-top: 12px;" class="mb-10">可用余额：${{
            filters.thousands(balanceInfo.balance)
          }}</span>
        <el-tooltip content="点击刷新余额" effect="light" placement="top">
          <el-button class="refreshBtn" type="text" @click="refreshBalance">
            <i class="iconfont icon-ylreplace"></i>
          </el-button>
        </el-tooltip>
      </el-col>
      <el-col :span="24" class="Text mb-10" :class="balanceInfo.cc_type == 1 ? 'color-danger' : ''">
        账户余额：${{ filters.thousands(balanceInfo.count) }}
      </el-col>
      <el-col :span="24" class="Text mb-10" v-if="balanceInfo.credit_type == 2">
        授信额度：${{ filters.thousands(balanceInfo.credit) }}
      </el-col>
    </el-row>
  </el-card>
</template>

<script setup>
import { onMounted, ref } from "vue";
import api from "@/request/api/account";
import filters from '@/base/filters/base'
import { ElMessage } from "element-plus";

const props = defineProps({
  type: { required: true, type: String }
})
let balanceInfo = ref({
  num_type: '',
  cc_type: '',
  c_type: '',
  n_type: '',
  credit_type: '',
  count: 0,
  credit: 0,
  balance: 0,
  bonus_balance: 0,
  total_balance: 0
})

let loading = ref(false)

const getBalanceInfo = async () => {
  let map = {
    'facebook': 1,
    'tiktok': 2,
    'bing': 3
  }

  if (!map[props.type]) return null
  loading.value = true
  let res = await api.common.getBalanceInfo(map[props.type])
  balanceInfo.value = res.data
  balanceInfo.value.total_balance = filters.thousands(Math.round(((res.data.balance ? res.data.balance * 1.0 : 0) + (res.data.bonus_balance ? res.data.bonus_balance * 1.0 : 0)) * 100) / 100)
  loading.value = false
}

const refreshBalance = async () => {
  let res = await api.common.refreshBalanceInfo(1).catch(() => {
  })

  if (res && res.code == 200) {
    ElMessage.success('刷新成功')
    getBalanceInfo()
  } else {
    ElMessage.error({ message: '刷新余额失败', duration: 1000 })
  }
}
onMounted(() => {
  getBalanceInfo()
})

let getBalance = () => {
  return balanceInfo.value
}

defineExpose({ getBalance, refreshBalance })

</script>

<style scoped>
.refreshBtn {
  padding: 0;
  min-height: auto;
  margin-left: 5px;
}

.balance-card {
  height: 180px;
}
</style>