<template>
  <el-row style="margin-bottom: 20px" class="firstRow">
    <el-col>
      <el-card>
        <ul class="pastAccount">
          <li>
            <i class="iconfont icon-zonglan"></i>
            <div>
              <p class="Text" style="margin: 0">账户总数</p>
              <div class="MainHead">{{ accountNumInfo.total_num }}</div>
            </div>
          </li>
          <li>
            <i class="iconfont icon-jinzhide"></i>
            <div>
              <p class="Text" style="margin: 0">被封账户数</p>
              <div class="MainHead">{{ accountNumInfo.blocked_num }}</div>
            </div>
          </li>
          <li>
            <i class="iconfont icon-jinxingzhong"></i>
            <div>
              <p class="Text" style="margin: 0">正在开户中</p>
              <div class="MainHead">{{ accountNumInfo.review_num }}</div>
            </div>
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>
  <el-card>
    <template #header>
      <el-row class="card-header" justify="space-between">
        <span class="SubHead">微软广告开户历史</span>
        <!-- <el-link @click="$router.push('/subMsg')" :underline="false"> -->
        <!-- <span style="font-size: 14px; color: #2b82ff">订阅开户通知</span> -->
        <!-- </el-link> -->
      </el-row>
    </template>
    <search-form-content ref="searchFormContentRef" :form-items="items" :fields="fields" :default-page-size="5"
      :fetch-data="fetchData" :format-status="formatStatus">
      <template #operations>
        <el-table-column label="备注" min-width="200" align="center" header-align="center">
          <template #default="{ row }">
            {{ row.reason }}
            <el-image v-if="row.url != ''" style="width: 50px; height: 50px" :src="row.url" :preview-src-list="row.urls"
              @click.stop="">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="XID" property="account_id" align="center" header-align="center" />
        <el-table-column label="CID" property="c_id" align="center" header-align="center" />
        <el-table-column label="操作" align="center" header-align="center">
          <template #default="{ row }">
            <el-button type="text" size="small" class="LinkText" @click="initSelectedAccount(row, false)"
              v-if="row.status == 1">查看
            </el-button>
            <el-button type="text" class="LinkText" size="small" @click="initSelectedAccount(row, true)"
              v-else>编辑</el-button>
          </template>
        </el-table-column>
      </template>
    </search-form-content>
  </el-card>
  <apply-account-form v-if="accountInfo && accountInfo.id" :edit-type="editable ? '编辑' : '查看'" :editable="editable"
    :type="type" :company="accountInfo" @close="; (editable = false), (type = ''), (accountInfo = {})"
    @refresh="refreshPageData" />
</template>
<script setup>
import { onMounted, ref } from 'vue'
import SearchFormContent from '@/components/base/SearchFormContent'
import api from '@/request/api/account'
import { ElMessage } from 'element-plus'
import base from '@/base/filters/base'

const dialogType = ref('')
let searchFormContentRef = ref()

const accountNumInfo = ref({
  blocked_num: 0,
  total_num: 0,
  review_num: 0,
})

const items = {
  timeRange: { type: 'daterange', label: '选择日期' },
  status: {
    type: 'selector',
    label: '账户状态',
    options: [
      { id: 1, name: '待审核' },
      { id: 2, name: '已审核' },
      { id: 3, name: '被驳回' },
    ],
  },
  company: { label: '营业执照' },
}

const fields = [
  { id: 'company', label: '营业执照', fixed: 'left' },
  { id: 'name', label: 'XID账户名称' },
  {
    id: 'status',
    label: '账户状态',
    format: (status) => {
      if (status === 1) return '已审核'
      if (status === 2) return '被驳回'
      return '待审核'
    },
  },
  { id: 'create_at', label: '提交时间' },
]

const formatStatus = (item) => {
  if ('status' !== item.column.property) return ''
  if (1 === item.row.status) return 'color-success'
  if (2 === item.row.status) return 'color-danger'
  return 'color-primary'
}
const fetchData = async (params) => {
  let res = await api.bing.getHistory(params)

  accountNumInfo.value = {
    blocked_num: res.data && res.data.blocked_num ? res.data.blocked_num : 0,
    total_num: res.data && res.data.total_num ? res.data.total_num : 0,
    review_num: res.data && res.data.review_num ? res.data.review_num : 0,
  }

  return {
    page: {
      total: res.data && res.data.total ? res.data.total : 0,
      page: params.page ? params.page : 1,
      pagesize: params.pagesize ? params.pagesize : 5,
    },
    data: res.list && res.list.length > 0 ? res.list : [],
  }
}

let editable = ref(false)
let type = ref('company')
let accountInfo = ref({})
let initSelectedAccount = async (row, isRowEditable) => {
  editable.value = isRowEditable
  let res = await api.bing.applyAccount({ id: row.id, type: 1 })
  if (!res || !res.data) return ElMessage.error('未能正常获取账户数据')
  type.value = res.data.c_id > 0 ? 'account' : 'company'
  accountInfo.value = {
    id: row.id,
    c_id: res.data.c_id,
    form: res.data,
  }
}

let refreshPageData = () => {
  if (
    !searchFormContentRef.value ||
    !searchFormContentRef.value.refreshData ||
    !searchFormContentRef.value.getSearchForm
  )
    return null
  searchFormContentRef.value.refreshData(searchFormContentRef.value.getSearchForm(), true)
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}

.createFB .el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 20px;
}

.fillGuide {
  color: #2b82ff;
  vertical-align: baseline !important;
  margin-left: 5px;
}

.pastAccount {
  display: flex;
  justify-content: space-evenly;

  li {
    position: relative;
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-right: 1px solid #d9d9d9;

    i.iconfont {
      font-size: 50px;
      color: #2b82ff;
    }

    i.el-icon-warning {
      position: absolute;
      top: 1px;
      right: 22px;
      font-size: 18px;
      color: #909399;
      cursor: pointer;
    }

    div {
      width: 50%;
    }
  }

  li:last-child {
    border: none;
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.firstRow {
  :deep(.el-card__body) {
    padding: 36px 20px;
  }
}

.el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 20px;
}

:deep(.el-alert__icon) {
  background-color: #e6f7ff !important;
  color: #2b82ff !important;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}
</style>
<script>
import ApplyAccountForm from '@/views/account/manage/bing/ApplyAccountForm'

export default {
  components: { ApplyAccountForm },
}
</script>
