<template>
  <el-form ref="accountFormRef" :inline="true" :model="accountForm">
    <el-row>
      <el-form-item label="广告账户" prop="account_id">
        <el-input placeholder="请输入广告账户ID或账户名称" size="small" clearable v-model.trim="accountForm.account_id"
          style="width: 220px"></el-input>
      </el-form-item>
      <el-form-item label="BMID" prop="BMID">
        <el-input placeholder="请输入BMID" size="small" clearable v-model="accountForm.BMID"></el-input>
      </el-form-item>
      <el-form-item label="闲置时长" prop="idleType">
        <el-select v-model="accountForm.idleType" placeholder="请选择" size="small" clearable>
          <el-option label="超过7天" value="1"></el-option>
          <el-option label="超过15天" value="2"></el-option>
          <el-option label="超过30天" value="3"></el-option>
          <el-option label="超过45天" value="4"></el-option>
          <el-option label="超过60天" value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-left: 14px">
        <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
        <el-button @click="resetFormBtn" size="small">重置</el-button>
      </el-form-item>
    </el-row>
  </el-form>

  <el-alert title="提示" description="" type="info" show-icon>
    <template #default>
      如需要绑定像素请在问题反馈提交需求工单，我们会在工作时间及时为您处理。格式：像素ID：xxx
      分享到BMID：xxx或账户ID：xxx。<br />
      若您需要对多个账户以及被封账户同时进行批量操作,请点击<span class="articleLink" @click="HandleAccounts">多账户批量操作</span>
    </template>
  </el-alert>

  <el-table :data="usedAccountList" :row-key="getAccountId" @sort-change="handleSortChange" style="width: 100%"
    :header-cell-style="{
      background: 'var(--el-table-row-hover-background-color)',
    }" @selection-change="handleSelectionChange">
    <el-table-column type="selection" :reserve-selection="true" :selectable="blockedRow" width="55" />
    <el-table-column property="name" :label="qudao == 1 ? '营业执照' : '账户名称'" min-width="150" />
    <el-table-column property="account_id" label="账户ID" />
    <el-table-column label="账户状态">
      <template #default="{ row }">
        <el-tag type="success" v-if="row.account_status == 1">正常</el-tag>
        <el-tag type="danger" v-else>被封</el-tag>
      </template>
    </el-table-column>
    <el-table-column property="idle_time" label="闲置时长" />
    <el-table-column property="account_balance" label="账户余额" sortable>
      <template #default="{ row }"> ${{ $filters.thousands(row.account_balance) }}</template>
    </el-table-column>
    <el-table-column property="bm_ids" label="绑定的BM" width="300" />
    <template #empty>
      <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
    </template>
  </el-table>
  <el-row>
    <el-col :span="12" style="padding-top: 15px">
      <el-button size="mini" @click="clearRemainder" :type="SelectNumber == 0 ? '' : 'primary'">余额清零</el-button>
      <el-button size="mini" @click="BMUnbind" :type="SelectNumber == 0 ? '' : 'primary'">BM解绑</el-button>
      <span class="Text" style="margin-left: 15px">已选择{{ SelectNumber }}个</span>
    </el-col>
    <el-col :span="12" style="text-align: right">
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
        :page-sizes="[2, 5, 10, 20, 50]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total" background>
      </el-pagination>
    </el-col>
  </el-row>

  <!-- BMID解绑对话框 -->
  <el-dialog v-model="BMUnbindDialogVisible" title="BM解绑" width="30%">
    <!-- 内容主体区域 -->
    <el-form ref="BMUnbindFormRef" :model="BMUnbindForm" :rules="BMUnbindFormRules" label-width="60px">
      <el-form-item label="BMID" prop="unbindId">
        <el-input size="small" placeholder="请输入解绑的BMID" clearable v-model.trim="BMUnbindForm.unbindId"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="BMUnbindFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="BMUnbindBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 操作多账户 -->
  <HandleAccountsDialog v-if="isDialogShow" @close="isDialogShow = false" />
</template>

<script>
import HandleAccountsDialog from './HandleAccountsDialog.vue'
import base from '../../../request/base'
import { ElLoading } from 'element-plus'
import filter from '@/base/filters/base'

export default {
  name: 'UsedAccount',
  components: {
    HandleAccountsDialog,
  },
  data() {
    //浏览器高度
    var height = window.innerHeight
    //浏览器宽度
    var width = window.innerWidth
    //浏览器
    // 浏览器检查结果
    let browserInfo = getBrowserInfo()
    var browser = browserInfo.browserName + '的版本是：' + browserInfo.versionInfo

    function getBrowserInfo() {
      let sysObj = {}
      let browserInfo = navigator.userAgent.toLowerCase()
      let regular = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
      let vsMsg = browserInfo.match(regular)
      sysObj.browserName = vsMsg[1].replace(/version/, "'safari")
      sysObj.versionInfo = vsMsg[2]
      return sysObj
    }

    // 获取操作系统信息
    function getOsInfo() {
      var userAgent = navigator.userAgent.toLowerCase()
      var name = 'Unknown'
      var version = 'Unknown'
      if (userAgent.indexOf('win') > -1) {
        name = 'Windows'
        if (userAgent.indexOf('windows nt 5.0') > -1) {
          version = 'Windows 2000'
        } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
          version = 'Windows XP'
        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
          version = 'Windows Vista'
        } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
          version = 'Windows 7'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
          version = 'Windows 8'
        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
          version = 'Windows 8.1'
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
          version = 'Windows 10'
        } else {
          version = 'Unknown'
        }
      } else if (userAgent.indexOf('iphone') > -1) {
        name = 'Iphone'
      } else if (userAgent.indexOf('mac') > -1) {
        name = 'Mac'
      } else if (
        userAgent.indexOf('x11') > -1 ||
        userAgent.indexOf('unix') > -1 ||
        userAgent.indexOf('sunname') > -1 ||
        userAgent.indexOf('bsd') > -1
      ) {
        name = 'Unix'
      } else if (userAgent.indexOf('linux') > -1) {
        if (userAgent.indexOf('android') > -1) {
          name = 'Android'
        } else {
          name = 'Linux'
        }
      } else {
        name = 'Unknown'
      }
      return { name, version }
    }

    var os = getOsInfo().name
    if (os == 'Windows') {
      os = getOsInfo().version
    }

    return {
      base: base,
      userInfo: '',
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5,
      },
      // 客户端信息
      width: width,
      height: height,
      browser: browser,
      os: os,
      // 查询用户信息的表单数据
      accountForm: {
        account_id: '',
        BMID: '',
        idleType: '',
        balance: null,
      },
      // 解绑对话框
      BMUnbindDialogVisible: false,
      // 添加用户的解绑表单数据
      BMUnbindForm: {
        unbindId: '',
      },
      // 添加解绑表单的验证规则对象
      BMUnbindFormRules: {
        unbindId: [
          { required: true, message: '请输入BMID', trigger: 'blur' },
          {
            min: 15,
            max: 18,
            message: 'BMID位数为15-18位',
            trigger: 'blur',
          },
        ],
      },
      total: 0,
      newArrayIds: [],
      SelectNumber: 0,
      // 闲置账户列表
      usedAccountList: [],
      // 是否预后付 1是预付 2后
      credit_typ: '',
      // 1代表渠道
      qudao: '',
      isDialogShow: false,
    }
  },
  mounted() {
    // 获取localStorage中的用户信息
    this.userInfo = this.$store.getters.userInfo
    this.getUsedAccountList()
  },
  methods: {
    // 查询按钮
    queryFormBtn() {
      this.$refs.accountFormRef.validate((valid) => {
        if (!valid) return
        this.getUsedAccountList()
      })
    },
    resetFormBtn() {
      this.$refs.accountFormRef.resetFields()
      this.getUsedAccountList()
    },
    // table需设置row-key
    getAccountId(account) {
      return account.account_id
    },
    // 获取列表数据
    async getUsedAccountList() {
      const { data: res } = await this.$http.post('cloudfb/idle_list', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        bm_id: this.accountForm.BMID,
        account_id: this.accountForm.account_id,
        idle_type: this.accountForm.idleType,
        balance: this.accountForm.balance,
      })
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.usedAccountList = res.advertising
      this.total = res.data.total
      this.credit_typ = res.data.credit_type
      this.qudao = res.data.qudao
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getUsedAccountList()
    },
    // 展示点击分页触发的函数
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getUsedAccountList()
    },
    handleSelectionChange(selection) {
      this.SelectNumber = selection.length
      const newArrIds = []
      selection.forEach((item) => {
        newArrIds.push(item.account_id)
      })
      this.newArrayIds = newArrIds
    },
    // 余额清零
    async clearRemainder() {
      if (this.SelectNumber == 0) {
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }
      if (this.credit_typ == 1) {
        var msg = `你确定要将所选的${this.SelectNumber}个账户清零吗？请先确保该广告账户下所有广告已暂停5小时以上。`
      } else {
        var msg = `你确定要将所选的${this.SelectNumber}个账户清零吗？`
      }
      const confirmResult = await this.$confirm(`${msg}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)

      if (confirmResult !== 'confirm') {
        return
      } else {
        // 发起余额清零的请求
        let loading = ElLoading.service({
          spinner: 'el-icon-loading',
          lock: true,
          text: '正在清零中，请稍后...',
        })
        const { data: res } = await this.$http
          .post('cloudfb/resets', {
            height: this.height,
            width: this.width,
            browser: this.browser,
            os: this.os,
            acc_ids: this.newArrayIds,
          })
          .finally(() => {
            if (loading && loading.close) loading.close()
          })

        if (res.code == 300) {
          this.$router.push('/login')
        }
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }

        // 触发更新余额模块的金额
        this.$bus.emit('getUserBalance')

        // 余额清零点击确定后的返回信息弹
        this.$alert(`以下是批量清零情况:<br>${res.msg}`, '信息', {
          confirmButtonText: '收到',
          dangerouslyUseHTMLString: true,
          callback: (action) => {
            // this.$message({
            //   type: 'info',
            //   message: `action: ${action}`
            // })
            this.$refs.allAccountRef.clearSelection()
            // 清零后重新请求刷新列表
            this.getAllAccountList()
          },
        })
      }
    },

    // BM解绑
    async BMUnbind() {
      if (this.SelectNumber == 0) {
        this.BMUnbindDialogVisible = false
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }
      this.BMUnbindDialogVisible = true
    },
    BMUnbindFormClose() {
      this.BMUnbindDialogVisible = false
      this.$refs.BMUnbindFormRef.resetFields()
    },
    // 解绑对话框确定按钮
    BMUnbindBtn() {
      this.$refs.BMUnbindFormRef.validate(async (valid) => {
        if (!valid) return
        this.BMUnbindDialogVisible = false
        // 再次确认是否解绑
        const confirmResult = await this.$confirm(
          `你确定要将所选的${this.SelectNumber}个广告账户,与${this.BMUnbindForm.unbindId}解绑吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).catch((err) => err)

        if (confirmResult !== 'confirm') {
          return
        } else {
          // 发起解绑的请求
          let loading = ElLoading.service({
            spinner: 'el-icon-loading',
            lock: true,
            text: '正在解绑中，请稍后...',
          })
          const { data: res } = await this.$http
            .post('cloudfb/set_bm', {
              height: this.height,
              width: this.width,
              browser: this.browser,
              os: this.os,
              acc_ids: this.newArrayIds,
              bm_id: this.BMUnbindForm.unbindId,
              type: 2,
            })
            .finally(() => {
              if (loading && loading.close) loading.close()
            })

          if (res.code == 300) {
            this.$router.push('/login')
          }
          if (res.code == 100) {
            return this.$message.error(`${res.msg}`)
          }
          // 解绑点击确定后的返回信息弹框
          this.$alert(`以下是批量解绑情况:<br>${res.msg}`, '信息', {
            confirmButtonText: '收到',
            dangerouslyUseHTMLString: true,
            callback: (action) => {
              this.$refs.allAccountRef.clearSelection()
              // 解绑后重新请求刷新列表
              this.getAllAccountList()
            },
          })
        }
      })
    },
    blockedRow(row, index) {
      if (row.account_status != 1) {
        return false
      } else {
        return true
      }
    },
    HandleAccounts() {
      this.isDialogShow = true
    },
    handleSortChange({ column, order }) {
      this.accountForm.balance = filter.transSortOrder(order)
      this.getUsedAccountList()
    },
  },
}
</script>

<style lang="less" scoped>
.el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 20px;
}

:deep(.el-alert__icon) {
  background-color: #e6f7ff !important;
  color: #2b82ff !important;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}

.articleLink {
  color: #2b82ff;
  text-decoration: none;
  cursor: pointer;
}
</style>
