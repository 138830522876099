<template>
  <el-dialog
    v-model="dialogShow"
    title="新建广告账户"
    :close-on-click-modal="false"
    custom-class="createGG"
    @close="closeCreateGG"
    width="600px"
  >
    <el-form
      ref="createGGFormRef"
      :model="createGGForm"
      :rules="createGGFormRules"
      class="createGG-form"
      label-width="120px"
    >
      <!-- <el-form-item label="营业执照名称" prop="licenseName"> -->
      <!-- <el-input v-model="createGGForm.licenseName" placeholder="请输入营业执照上的公司名称" size="small" clearable /> -->
      <!-- </el-form-item> -->
      <el-form-item label="账号币种" prop="currency">
        <el-select v-model="createGGForm.currency" placeholder="请选择币种" size="small" :disabled="editDisable">
          <el-option
            v-for="(item, index) in currencyData"
            :label="item.label"
            :value="item.value"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账号时区" prop="timezone">
        <el-select
          v-model="createGGForm.timezone"
          placeholder="请选择账号时区"
          size="small"
          filterable
          :disabled="editDisable"
        >
          <el-option
            v-for="(item, index) in timezoneData"
            :label="item.label"
            :value="item.value"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="行业" prop="industry">
        <el-cascader
          placeholder="请选择行业"
          v-model="createGGForm.industry"
          :options="industryData"
          :props="{
            expandTrigger: 'hover',
          }"
          size="small"
          :disabled="editDisable"
        />
      </el-form-item>
      <el-form-item
        v-for="(item, index) in createGGForm.accountName"
        :prop="`accountName[${index}].value`"
        :rules="[
          { required: true, message: '请输入账号名称', trigger: 'blur' },
          { max: 15, message: '每个账户限制最多15个字', trigger: 'blur' },
        ]"
      >
        <template #label v-if="index === 0"> 账号名称 </template>
        <el-row>
          <el-col :span="20">
            <el-input
              v-model="item.value"
              placeholder="请输入账号名称"
              size="small"
              clearable
              :disabled="editDisable"
            />
          </el-col>
          <el-col :span="4" style="display: flex; align-items: center; justify-content: center">
            <el-button
              type="text"
              class="circleBtn"
              circle
              size="small"
              v-if="index == 0"
              @click="addAccountName"
              :disabled="this.createGGForm.accountName.length === 5"
              :class="{ disabledButton: this.createGGForm.accountName.length === 5 }"
            >
              <i class="el-icon-circle-plus"></i>
            </el-button>
            <el-button
              type="text"
              class="circleBtn"
              circle
              size="small"
              @click="removeAccountName(item.id, item.value)"
              :disabled="editDisable"
              v-else
            >
              <i class="el-icon-remove"></i>
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="推广链接" prop="url" class="urlItem">
        <el-select
          v-model="createGGForm.url"
          multiple
          filterable
          allow-create
          size="small"
          default-first-option
          :reserve-keyword="false"
          placeholder="请输入推广URL，如有多个链接请输入后按回车选择"
          style="width: 100%"
        >
        </el-select>
        <p class="Text" style="margin: 0; position: absolute; top: auto; padding-top: 5px">
          注：每个账户都会关联全部的推广链接
        </p>
      </el-form-item>
      <!-- <el-form-item label="营业执照" prop="licensePath" style="margin-top: 50px;"> -->
      <!-- <el-upload -->
      <!-- ref="files" -->
      <!-- :action="uploadURL" -->
      <!-- list-type="picture-card" -->
      <!-- :limit="1" -->
      <!-- accept=".jpeg,.png,.jpg" -->
      <!-- :on-preview="handlePictureCardPreview" -->
      <!-- :on-remove="handleRemove" -->
      <!-- :before-upload="beforeImgUpload" -->
      <!-- :on-success="handleSuccess" -->
      <!-- :headers="headerObj" -->
      <!-- > -->
      <!-- <i class="el-icon-upload"></i> -->
      <!-- <div class="el-upload__text"> -->
      <!-- <em>点击上传</em><br /> -->
      <!-- 支持jpeg、png、jpg格式 -->
      <!-- </div> -->
      <!-- </el-upload> -->
      <!-- <el-dialog v-model="imageDialogVisible"> -->
      <!-- <img style="width: 100%" :src="dialogImageUrl" alt="" /> -->
      <!-- </el-dialog> -->
      <!-- </el-form-item> -->
      <el-form-item label="账户访问邮箱" prop="email">
        <el-input
          v-model="createGGForm.email"
          placeholder="请输入谷歌账户的访问邮箱"
          size="small"
          clearable
          :disabled="editDisable"
        />
      </el-form-item>
      <p class="Text" style="width: 100%; text-align: right">
        注：请填入Gmail邮箱，下户后会向此邮箱发送邀请链接进行账户配置等操作。
      </p>
    </el-form>
    <template #footer>
      <el-button size="small" type="primary" @click="submitFormBtn">提交</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { nanoid } from 'nanoid'
import { ElLoading } from 'element-plus'
import base from '../../../request/base'
export default {
  name: 'GGNewOpen',
  data() {
    const urlValidRule = (rule, value, cb) => {
      let reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/
      value.forEach((item) => {
        if (!reg.test(item)) {
          cb(new Error('推广链接格式错误，需带http或者https'))
        }
      })
      return cb()
    }

    return {
      base: base,
      dialogShow: true,
      editDisable: false,
      editReload: false,
      currencyData: [],
      industryData: [],
      timezoneData: [],
      req_id: 0,
      createGGForm: {
        licenseName: '',
        currency: '',
        timezone: '',
        industry: '',
        accountName: [
          {
            id: nanoid(),
            value: '',
          },
        ],
        url: [],
        licensePath: '',
        email: '',
      },
      createGGFormRules: {
        licenseName: [
          { required: true, message: '营业执照不能为空', trigger: 'blur' },
          {
            pattern: /^[\u4e00-\u9fa5\(\)\（\）]{0,}$/,
            message: '只能使用中文营业执照开户',
            trigger: 'change',
          },
        ],
        currency: [{ required: true, message: '请选择币种', trigger: 'change' }],
        timezone: [{ required: true, message: '请选择账号时区', trigger: 'change' }],
        industry: [{ required: true, message: '请选择行业', trigger: 'change' }],
        'accountName[0].value': [{ required: true, message: '请输入账号名称', trigger: 'blur' }],
        url: [
          { required: true, message: '请输入带http或者https推广URL', trigger: 'change' },
          { validator: urlValidRule, trigger: 'change' },
        ],
        licensePath: [{ required: true, message: '请上传营业执照', trigger: 'change' }],
        email: [
          { required: true, message: '请输入账户访问邮箱', trigger: 'blur' },
          {
            pattern: /(^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/,
            message: '请输入正确格式的邮箱号码',
            trigger: 'change',
          },
        ],
      },
      // 营业执照图片上传的地址
      // 图片上传时的headers请求头
      headerObj: {
        Authorization: this.$store.getters.userInfo.token,
      },
      // 图片上传的地址
      // 正式
      uploadURL: `${base.admin_url}/cloud/file/uploadfile_gg`,
      srcThumb: '',
      srcThumbList: [],
      imageDialogVisible: false,
      dialogImageUrl: '',
      license_code: '',
      license_msg: '',
      checkLicense: false,
      checkLicenseName: '',
    }
  },
  mounted() {
    this.$bus.on('openGGDialog', this.openGGDialog)
    this.getCreateFormData()
  },
  methods: {
    openGGDialog() {
      this.dialogShow = true
    },
    closeCreateGG() {
      this.$refs.createGGFormRef.resetFields()
      this.$emit('close')
    },
    // 添加账号名称列表
    addAccountName() {
      const valueObj = { id: nanoid(), value: '' }
      this.createGGForm.accountName.push(valueObj)
    },
    // 删除账号名称列表
    removeAccountName(id, value) {
      this.createGGForm.accountName = this.createGGForm.accountName.filter((item) => item.id !== id)
      this.checkoutCode1[value] = ''
    },
    async getCreateFormData() {
      const { data: res } = await this.$http.post('cloudgg/account_data')
      this.currencyData = res.data.currency
      this.timezoneData = res.data.timezone
      this.industryData = Object.values(res.data.industry)
    },
    // 处理图片预览功能
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.response.path
      this.imageDialogVisible = true
    },
    // 移除图片的按钮
    handleRemove() {
      if (this.editReload == false) {
        this.$http.post('file/delete_img', {
          name: this.createGGForm.licensePath,
        })
      }
      this.createGGForm.licensePath = ''
    },
    beforeImgUpload(file) {
      this.checkLicense = true
      var img = file.name.substring(file.name.lastIndexOf('.') + 1)
      const suffix = img === 'jpg'
      const suffix2 = img === 'png'
      const suffix3 = img === 'jpeg'
      const suffix4 = img == 'JPG'
      const suffix5 = img == 'PNG'
      const suffix6 = img == 'JPEG'
      if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6) {
        this.$message.error('仅支持上传图片！')
        return false
      }
    },
    // 图片上传成功的回调
    handleSuccess(response) {
      this.license_code = response.code
      if (this.license_code == 100) {
        this.license_msg = response.msg
        return this.$message.error(response.msg)
      }
      this.createGGForm.licensePath = response.path1
      if (this.createGGForm.licenseName != response.license_name) {
        this.license_msg = '上传的营业执照与您填写的营业执照名称不对应'
        this.$message.error('上传的营业执照与您填写的营业执照名称不对应')
        this.license_code = 100
      }
    },
    async submitFormBtn() {
      this.$refs.createGGFormRef.validate(async (valid) => {
        if (!valid) return
        // if (this.license_code == 100) {
        //   return this.$message.error(this.license_msg)
        // }
        // if (this.checkLicense == false) {
        //   if (this.createGGForm.licenseName != this.checkLicenseName) {
        //     return this.$message.error('您填写的营业执照名称与上传的营业执照不符合')
        //   }
        // }

        const loading = ElLoading.service({
          spinner: 'el-icon-loading',
          lock: true,
          text: '正在提交，请稍后...',
        })

        const { data: res } = await this.$http
          .post('Cloudgg/open_account', {
            req_id: this.req_id,
            // legal_name: this.createGGForm.licenseName,
            currency: this.createGGForm.currency,
            timezone: this.createGGForm.timezone,
            industry: this.createGGForm.industry[1],
            name: this.createGGForm.accountName.map((item) => item.value),
            promotion_link: this.createGGForm.url,
            // file_name: this.createGGForm.licensePath,
            email: this.createGGForm.email,
          })
          .finally(() => {
            loading.close()
          })

        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }
        if (res.code == 300) {
          this.$router.push('/login')
        }
        this.dialogShow = false
        this.$message.success(res.msg)
        if (this.editReload == true) {
          this.$bus.emit('getHistoryList')
        } else {
          this.$router.push({
            name: 'account_history',
            query: {
              type: 'Google',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
:deep(.el-upload),
:deep(.el-upload-dragger) {
  width: 100% !important;

  .el-upload__text {
    position: relative;
    bottom: 60px;
    line-height: 1.5;
  }

  em {
    font-style: normal;
  }
}

.el-upload--picture-card i {
  font-size: 55px;
  color: #2b82ff;
}

:deep(.el-upload--picture-card) {
  border: 1px dashed #2b82ff;
}

:deep(.el-form-item:last-child),
:deep(.el-form-item:nth-child(7)) {
  .el-form-item__error {
    position: relative;
    top: 0;
  }
}

.circleBtn {
  padding: 0;

  .el-icon-circle-plus,
  .el-icon-remove {
    font-size: 30px;
    color: #2b82ff;
  }
}

.disabledButton {
  opacity: 0.5;
  cursor: not-allowed;
}

.urlItem {
  margin-bottom: 50px;
}
</style>
