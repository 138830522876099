<template>
  <!-- 账户信息 -->
  <el-row style="margin-bottom: 20px" class="firstRow">
    <el-col>
      <el-card>
        <ul class="pastAccount">
          <li>
            <i class="iconfont icon-zonglan"></i>
            <div>
              <p class="Text" style="margin: 0">账户总数</p>
              <div class="MainHead">{{ total_num }}</div>
            </div>
          </li>
          <li>
            <i class="iconfont icon-jinxingzhong"></i>
            <div>
              <p class="Text" style="margin: 0">正在开户中</p>
              <div class="MainHead">{{ review_num }}</div>
            </div>
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>

  <!-- 开户历史 -->
  <el-row>
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead">Google开户历史</span>
            <!-- <router-link to="/subMsg"><span style="font-size: 14px; color: #2b82ff">订阅开户通知</span></router-link> -->
          </div>
        </template>
        <el-form ref="historyFormRef" :inline="true" :model="historyForm" style="padding-top: 15px">
          <el-form-item label="选择日期" prop="timeRange">
            <el-date-picker v-model="historyForm.timeRange" type="daterange" size="small" start-placeholder="开始日期"
              end-placeholder="结束日期" value-format="X" style="margin-left: 14px" clearable></el-date-picker>
          </el-form-item>
          <el-form-item label="账户名称" prop="accountName">
            <el-input placeholder="请输入账户名称" size="small" v-model="historyForm.accountName" clearable></el-input>
          </el-form-item>
          <el-form-item label="账户状态" prop="status">
            <el-select v-model="historyForm.status" placeholder="请选择状态" size="small" clearable>
              <el-option label="审核中" value="1"></el-option>
              <el-option label="已获批" value="2"></el-option>
              <el-option label="未获批" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 14px">
            <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
            <el-button @click="resetFormBtn" size="small">重置</el-button>
          </el-form-item>
        </el-form>

        <el-alert title="提示" description="" type="info" show-icon>
          <template #default>
            若超过一个工作日您的开户申请审核状态仍未更新，请寻求人工客服的帮助。<br />
            若您未能查询到您提交的开户申请记录，请在提交开户申请后第二天早上8点后点击校验营业执照进行审核校验。
          </template>
        </el-alert>

        <el-table ref="historyTabelRef" :data="historyList" style="width: 100%" :header-cell-style="{
          background: 'var(--el-table-row-hover-background-color)',
        }">
          <!-- <el-table-column property="company" label="营业执照名称" min-width="180" fixed /> -->
          <el-table-column property="create_date" label="提交时间" min-width="150" />
          <el-table-column property="name" label="账户名称" min-width="180" />
          <el-table-column label="状态" min-width="130">
            <template #default="{ row }">
              <el-tag type="success" v-if="row.status == 1">已通过</el-tag>
              <el-tag type="danger" v-else-if="row.status == 2">被拒绝</el-tag>
              <el-tag v-else>待审核</el-tag>
            </template>
          </el-table-column>
          <el-table-column property="update_date" label="审核时间" min-width="150" />
          <el-table-column property="reason" label="审核原因" min-width="300">
            <template #default="{ row }">
              <span>
                {{ row.reason }}
              </span>
              <div v-if="row.url != ''">
                <el-image style="width: 50px; height: 50px" :src="row.url" :preview-src-list="row.urls" @click.stop="">
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column property="account_id" label="广告账户ID" width="200" />
          <el-table-column property="promotable_urls" label="操作" min-width="100" fixed="right">
            <template #default="{ row }">
              <el-button type="text" size="small" class="LinkText" @click.stop="handleCreateGGDialog(row)"
                v-if="row.status == 1">查看</el-button>
              <el-button type="text" class="LinkText" size="small" @click.stop="handleCreateGGDialog(row)"
                v-else>编辑</el-button>
            </template>
          </el-table-column>
          <template #empty>
            <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
          </template>
        </el-table>
        <!-- 分页器 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.page" :page-sizes="[5, 10]" :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" background>
        </el-pagination>
      </el-card>
    </el-col>
  </el-row>
  <!-- TT查看或编辑对话框 -->
  <GGReviseOpen v-if="isDialogShow" @close="isDialogShow = false" :row="row" />
</template>

<script>
import GGReviseOpen from './GGReviseOpen.vue'
export default {
  name: 'GGOpenHistory',
  components: { GGReviseOpen },
  data() {
    // 邮箱集验证规则
    const emailValidRule = (rule, value, cb) => {
      let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      value.forEach((item) => {
        if (!reg.test(item)) {
          cb(new Error('邮箱格式不正确'))
        }
      })
      return cb()
    }
    return {
      active_rate: '',
      total_num: '',
      blocked_num: '',
      idle_num: '',
      review_num: '',
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5,
      },
      total: 0,
      historyForm: {
        timeRange: '',
        accountName: '',
        status: '',
      },
      historyList: [],
      noticeDialogVisible: false,
      confirmEmailForm: {
        emailValue: [],
      },
      confirmEmailFormRules: {
        emailValue: [{ validator: emailValidRule, trigger: 'change' }],
      },
      sub_type: 0,
      isDialogShow: false,
      row: {},
    }
  },
  mounted() {
    this.$bus.on('getHistoryList', this.getHistoryList)
    this.getHistoryList()
    // this.getBindEmail()
  },
  methods: {
    // async getBindEmail() {
    //   const { data: res } = await this.$http.post('cloudfb/set_email')
    //   this.confirmEmailForm.emailValue = res.data.tt_sub
    //   this.sub_type = res.data.tt_sub_type
    // },
    async getHistoryList() {
      const { data: res } = await this.$http.post('cloudgg/account_history', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        name: this.historyForm.accountName,
        status: this.historyForm.status,
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }

      this.historyList = res.list
      this.active_rate = res.data.active_rate
      this.total = res.data.total
      this.total_num = res.data.total_num
      this.blocked_num = res.data.blocked_num
      this.idle_num = res.data.idle_num
      this.review_num = res.data.review_num
    },
    queryFormBtn() {
      this.getHistoryList()
    },
    resetFormBtn() {
      this.$refs.historyFormRef.resetFields()
      this.getHistoryList()
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getHistoryList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getHistoryList()
    },
    handleCreateGGDialog(row) {
      this.isDialogShow = true
      this.row = row
    },
  },
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}

.pastAccount {
  display: flex;
  justify-content: space-evenly;

  li {
    position: relative;
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-right: 1px solid #d9d9d9;

    i.iconfont {
      font-size: 50px;
      color: #2b82ff;
    }

    i.el-icon-warning {
      position: absolute;
      top: 1px;
      right: 22px;
      font-size: 18px;
      color: #909399;
      cursor: pointer;
    }

    div {
      width: 50%;
    }
  }

  li:last-child {
    border: none;
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.firstRow {
  :deep(.el-card__body) {
    padding: 36px 20px;
  }
}

:deep(.el-table__expanded-cell) {
  padding: 0 0 0 48px;
}

.el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 20px;
}

:deep(.el-alert__icon) {
  background-color: #e6f7ff !important;
  color: #2b82ff !important;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}
</style>
