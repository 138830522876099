<template>
  <div class="apply-account">
    <search-form ref="searchFormRef" :form-items="items" :fetch-data="fetchData" :default-page-size="5" />
    <el-row class="mb-10" v-for="company in data" :key="company.c_id">
      <el-col :span="20" class="company-info mb-10">
        <span>{{ company.c_id }}：{{ company.c_name }}</span>
        <el-button
          type="primary"
          plain
          size="small"
          v-if="company.accounts.length < 6"
          @click=";(dialogType = 'account'), (editable = true), (selected = company)"
        >
          新增XID账户
        </el-button>
      </el-col>
      <el-col class="account-info" :span="20">
        <div class="account-grey account-header">XID</div>
        <div class="account-grey account-header">XID账户名称</div>
      </el-col>
      <el-col class="account-info" v-for="(account, index) in company.accounts" :span="20" :key="account.x_id">
        <div :class="{ 'account-white': index % 2 === 0, 'account-grey': index % 2 !== 0 }">
          <span>{{ account.x_id }}</span>
        </div>
        <div :class="{ 'account-white': index % 2 === 0, 'account-grey': index % 2 !== 0 }">
          <span>{{ account.x_name }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 40px; margin-bottom: 10px">
      <el-col :span="20">
        <el-button
          class="width-100 add-cid-button"
          type="primary"
          size="small"
          @click=";(dialogType = 'company'), (editable = true)"
        >
          新增CID账户
        </el-button>
      </el-col>
    </el-row>
    <apply-account-form
      v-if="dialogType"
      :editable="editable"
      :type="dialogType"
      :company="selected"
      @close=";(dialogType = ''), (selected = {}), (editable = false)"
    />
  </div>
  <el-row justify="end" class="pagination">
    <el-pagination
      v-if="pageData"
      v-model:page-size="pageData.pagesize"
      v-model:current-page="pageData.page"
      :total="pageData.total"
      :page-sizes="[5, 10, 20, 40, 60, 80, 100, 200, 300, 400]"
      @size-change="pageChange"
      @current-change="pageChange"
      layout="total, sizes, prev, pager, next, jumper"
      background
    />
  </el-row>
</template>
<script setup>
import { getCurrentInstance, ref } from 'vue'
import api from '@/request/api/account'
import SearchForm from '@/components/base/SearchForm'
import ApplyAccountForm from '@/views/account/manage/bing/ApplyAccountForm'

const { proxy } = getCurrentInstance()
const items = {
  keyword: { label: 'CID/CID账户名称' },
}

const data = ref([])
const searchFormRef = ref()
const pageData = ref({
  page: 1,
  pagesize: 5,
  total: 0,
})
const fetchData = async (params, withPage = false) => {
  let res = await api.bing.cIdList(
    withPage ? Object.assign(params, pageData.value) : Object.assign(pageData.value, params)
  )
  data.value = res.data
  pageData.value = {
    page: pageData.value.page,
    pagesize: pageData.value.pagesize,
    total: res.total ? res.total : 0,
  }
}

const pageChange = () => {
  fetchData(searchFormRef.value.getSearchForm(), true)
}

const dialogType = ref('')
const selected = ref({})
const editable = ref(false)
</script>
<style lang="less" scoped>
.apply-account {
  position: relative;
  min-height: 70vh;
}
.company-info {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #262626;
  display: flex;
  justify-content: space-between;

  :deep(.el-button--small) {
    background: #ffffff;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    border: 1px solid #b3d8ff;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #2b82ff;
    padding: 8px 14px;
  }
}

.account-info {
  margin: 0 0 -1px -1px;
  text-overflow: ellipsis;
  min-height: 40px;
  font-size: 14px;
  display: flex;

  div {
    flex: 1;
  }

  .account-header {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
  }

  span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #595959;
  }
}

.account-grey {
  background: #f5f7fa;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account-white {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.el-form-item__label) {
  color: #262626;
}

:deep(.el-button--small) {
  padding: 8px 14px;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
}

.pagination {
  position: relative;
  right: 0;
  bottom: 0;
}
</style>
