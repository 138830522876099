<template>
  <el-dialog :title="editType + (type === 'company' ? 'CID账户' : 'XID账户')"
             width="40%"
             v-model="dialogShow"
             @close="$emit('close')">
    <el-row class="mb-20">
      <el-alert v-if="type === 'company' && editType === '新建'"
                show-icon type="warning"
                :closable="false">
        新建CID账户时，会默认在此CID账户下新建一个XID账户
      </el-alert>
    </el-row>
    <el-form ref="formRef" :model="form" :rules="rules" label-position="right" label-width="150px">
      <el-form-item label="XID账户名称" prop="name" size="small">
        <el-input v-model="form.name" placeholder="请输入您开户的账号名称" :disabled="!editable" clearable/>
      </el-form-item>
      <el-form-item label="账户联系人邮箱" prop="email" size="small">
        <el-input v-model="form.email" placeholder="请输入邮箱" :disabled="!editable" clearable/>
        <el-row class="color-help font-small" v-if="type === 'company'">
          注：此邮箱会收到账户验证邮件
        </el-row>
      </el-form-item>
      <el-form-item v-if="type === 'company'" label="联系人姓名" required size="small">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item prop="first_name" size="small">
              <el-input v-model="form.first_name" placeholder="名" :disabled="!editable" clearable/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="last_name" size="small">
              <el-input v-model="form.last_name" placeholder="姓" :disabled="!editable" clearable/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="行业" prop="industry" size="small">
        <el-select class="width-100" v-model="form.industry" :disabled="!editable" filterable clearable>
          <template v-if="preparedData.industry">
            <el-option v-for="item in preparedData.industry"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="账号时区" prop="timezone" size="small">
        <el-select class="width-100" v-model="form.timezone" :disabled="!editable" filterable clearable>
          <template v-if="preparedData.timezone">
            <el-option v-for="timezone in preparedData.timezone"
                       :key="timezone.value"
                       :label="timezone.label"
                       :value="timezone.value">
            </el-option>
          </template>
        </el-select>
        <el-row class="color-help font-small">
          注：账户广告活动运作的时区
        </el-row>
      </el-form-item>
      <el-form-item label="法定公司中文名称" prop="company" size="small">
        <el-input v-model="form.company" placeholder="请输入您的中文公司名称" :disabled="!editable" clearable/>
      </el-form-item>
      <el-form-item label="法定公司英文名称" prop="company_eng" size="small">
        <el-input v-model="form.company_eng" placeholder="请输入您的英文公司名称" :disabled="!editable" clearable/>
      </el-form-item>
      <el-form-item label="营业地区" required size="small">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item prop="country" size="small">
              <el-select v-model="form.country" placeholder="国家" @change="form.state = '',form.city = ''"
                         :disabled="!editable" clearable>
                <template v-if="preparedData.country">
                  <el-option v-for="country in preparedData.country"
                             :key="country.value"
                             :label="country.label"
                             :value="country.value">
                  </el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="state" size="small" v-if="form.country">
              <el-select v-model="form.state" placeholder="省份" @change="form.city = ''" :disabled="!editable" clearable>
                <template v-if="preparedData.state">
                  <el-option v-for="state in preparedData.state"
                             :key="state.value"
                             :label="state.label"
                             :value="state.value">
                  </el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="city" size="small" v-if="form.state">
              <el-input v-model="form.city" placeholder="市/县" :disabled="!editable" clearable/>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form-item>
      <el-form-item label="公司地址" prop="address" size="small">
        <el-input v-model="form.address" placeholder="请输入您的公司地址" :disabled="!editable" clearable/>
      </el-form-item>
      <el-form-item label="公司地址邮编" prop="zip_code" size="small">
        <el-input v-model="form.zip_code" placeholder="请输入您公司所在地的邮编" :disabled="!editable" clearable/>
      </el-form-item>
      <el-form-item label="推广链接" prop="promotion_link" size="small">
        <el-input v-model="form.promotion_link" placeholder="请输入推广链接" :disabled="!editable" clearable/>
      </el-form-item>
      <el-form-item label="品牌名" prop="brand_name" size="small">
        <el-input v-model="form.brand_name" placeholder="请输入您的品牌名称" :disabled="!editable" clearable/>
        <el-row class="color-help font-small">
          <span class="width-100 font-small" style="line-height: 20px">注：请输入落地页的主域名或副品牌名（英文），供生成账号名称使用</span>
          <span class="width-100 font-small" style="line-height: 20px">例如：Shein</span>
        </el-row>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button :type="editable?'':'primary'"
                   size="small"
                   @click="dialogShow=false">
          {{ editable ? '取 消' : '关闭' }}
        </el-button>
        <el-button v-if="editable" size="small" type="primary" @click="submit">申请开户</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import api from "@/request/api/account";
import {ElLoading, ElMessage} from "element-plus";
import SearchForm from "@/components/base/SearchForm";
import validator from "@/base/validator";

let emit = defineEmits(['refresh'])
let props = defineProps({
  editable: {required: true, type: Boolean},
  type: {required: true, type: String},
  company: {type: Object},
  editType: {default: '新建'}
})

const {proxy} = getCurrentInstance()

let dialogShow = ref(true)
let formRef = ref(null)
let preparedData = ref({
  currency: [],
  timezone: [],
  country: [],
  state: [],
  city: [],
  industry: [],
})

console.log(props.company)

let form = ref({
  req_id: props.company && props.company.form && props.company.form.req_id ? props.company.form.req_id : 0,
  name: props.company && props.company.form && props.company.form.name ? props.company.form.name : '',
  email: props.company && props.company.form && props.company.form.email ? props.company.form.email : '',
  industry: props.company && props.company.form && props.company.form.industry ? props.company.form.industry : '',
  timezone: props.company && props.company.form && props.company.form.timezone ? props.company.form.timezone : '',
  company: props.company && props.company.form && props.company.form.company ? props.company.form.company : '',
  company_eng: props.company && props.company.form && props.company.form.company_eng ? props.company.form.company_eng : '',
  country: props.company && props.company.form && props.company.form.country ? props.company.form.country : '',
  state: props.company && props.company.form && props.company.form.state ? props.company.form.state : '',
  city: props.company && props.company.form && props.company.form.city ? props.company.form.city : '',
  address: props.company && props.company.form && props.company.form.address ? props.company.form.address : '',
  zip_code: props.company && props.company.form && props.company.form.zip_code ? props.company.form.zip_code : '',
  promotion_link: props.company && props.company.form && props.company.form.promotion_link ? props.company.form.promotion_link : [],
  c_id: props.type === 'company' ? 0 : (props.company && props.company.c_id ? props.company.c_id : 0),
  first_name: props.company && props.company.form && props.company.form.first_name ? props.company.form.first_name : '',
  last_name: props.company && props.company.form && props.company.form.last_name ? props.company.form.last_name : '',
  brand_name: props.company && props.company.form && props.company.form.brand_name ? props.company.form.brand_name : '',
})

let rules = ref({
  name: [
    {required: true, message: '请输入账号名称', trigger: 'blur'},
    {
      validator: validator.validCharactor, max: 20,
      message: '最长20个英文字符或10个汉字', trigger: 'change'
    }
  ],
  email: [
    {required: true, message: '请输入账户联系人邮箱', trigger: 'blur'},
    {
      pattern: /(^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/,
      message: '请输入正确格式的邮箱号码',
      trigger: 'change'
    }
  ],
  industry: {required: true, message: '请选择行业', trigger: 'blur'},
  timezone: {required: true, message: '请选择账号时区', trigger: 'blur'},
  company: {required: true, message: '请输入法定公司中文名称', trigger: 'blur'},
  company_eng: {required: true, message: '请输入法定公司英文名称', trigger: 'blur'},
  country: {required: true, message: '请选择国家', trigger: 'blur'},
  state: {required: true, message: '请选择省份', trigger: 'blur'},
  city: {required: true, message: '请输入市/县', trigger: 'blur'},
  address: {required: true, message: '请输入公司地址', trigger: 'blur'},
  zip_code: {required: true, message: '请输入公司地址邮编', trigger: 'blur'},
  first_name: {required: true, message: '请输入名', trigger: 'blur'},
  last_name: {required: true, message: '请输入姓', trigger: 'blur'},
  promotion_link: [
    {required: true, message: '请输入带http或者https推广链接', trigger: 'change'},
    {validator: validator.urlValidRule, trigger: 'change'}
  ],
  brand_name: [
    {required: true, message: '请输入品牌名称', trigger: 'blur'},
    {
      pattern: /^[A-Za-z0-9 -.]+$/,
      message: '品牌名称仅可包含英文、数字，最长20个英文字符',
      trigger: 'change'
    },
    {
      min: 1,
      max: 20,
      message: '品牌名称仅可包含英文、数字，最长20个英文字符',
      trigger: 'change'
    }
  ]

})

let openDocument = () => {
  window.open(`document/微软广告开户命名规范.pdf`, '_blank')
}

let submit = async () => {
  const result = await formRef.value.validate()
  if (true !== result) return null
  let loadingInstance = ElLoading.service({
    fullscreen: true,
    spinner: 'el-icon-loading',
    lock: true,
    text: '正在提交，请稍后...'
  })
  let res = await api.bing.applyAccount(form.value).finally(() => {
    loadingInstance.close()
  })
  if (res.code === 200) return ElMessage.success({
    message: res.msg ? res.msg : '申请成功', duration: 1000, onClose() {
      dialogShow.value = false
      emit('refresh')
      proxy.$router.push({path: '/account_history', query: {type: 'bing'}})
    }
  })

  ElMessage.error(res.msg ? res.msg : '申请失败')
}

onMounted(async () => {
  let res = await api.bing.prepareApplyData()
  preparedData.value = {
    currency: res.data && res.data.currency ? res.data.currency : [],
    timezone: res.data && res.data.timezone ? res.data.timezone : [],
    country: res.data && res.data.country ? res.data.country : [],
    state: res.data && res.data.state ? res.data.state : [],
    city: res.data && res.data.city ? res.data.city : [],
    industry: res.data && res.data.industry ? res.data.industry : [],
  }
  if (preparedData.value.country.length === 1) form.value.country = preparedData.value.country[0].value
})
</script>
<style scoped>
.font-small .el-link--inner {
  font-size: 12px !important;
}
</style>